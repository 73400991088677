import React, { useState } from 'react'
import {Row,Col,ListGroup,Button} from 'react-bootstrap'
import { useParams } from "react-router-dom";

import Model from 'react-body-highlighter';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'; 

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import "react-datepicker/dist/react-datepicker.css";

import trash from '../Common/img/icon-trash.svg'


import Layout from '../../Core/Layout';
import PatientPainEditable from './PatientPainEditable';


function PatientConditionContent() {
	const { patientId} =  useParams();
	
	const [pain_list,addPain] = useState([])

	// Save state in checkboxes 
	
	function bodyPain({ muscle }) {
		const existingPain = pain_list.find(pain => pain.muscles.includes(muscle));
		const updatedPainList = [...pain_list];
		if (existingPain) {
		  const updatedPain = {
			...existingPain,
			frequency: existingPain.frequency === 1 ? 2 : existingPain.frequency === 2 ? 3 : 1
		  };
		  const index = pain_list.indexOf(existingPain);
		  updatedPainList[index] = updatedPain;
		} else {
		  const newPain = {
			name: muscle + " pain",
			frequency: 1,
			date: new Date(),
			muscles: [muscle],
		  };
		  updatedPainList.unshift(newPain);
		}
		addPain(updatedPainList);
	  }
	

	// Delete the item with the given id
  // This function will be called when an "X" button is clicked
		function removeItem (elem) {
			console.log(elem)
			const updatedList = pain_list.filter((item) => item.muscles[0] !== elem);
			console.log(updatedList)
			addPain(updatedList);
		};
	  const sidebarContent = {
		'title':"Initial Assessment", 
		'parent':`Patient ${patientId}`,
		'parent_url':`/physiotherapy/${patientId}`,
		'menu' : 'assessment',
		'patientId': patientId,
		'button' : 'next',
		'button_url': `/physiotherapy/${patientId}/sensorimotor`
	  }	  
  	
  return (
	<>
	<Layout sidebarContent={sidebarContent} defaultkey="0">
		<Row>
			
		<Col lg="5">
			<h2>Pain Localization</h2>
		
			<Tabs >
				<TabList className="nav nav-tabs">
					<Tab className="nav-item  btn btn-sm">Front</Tab>											
					<Tab className="nav-item  btn btn-sm">Back</Tab>
				</TabList>

			
			<TabPanel className="text-center">

				<p>Right side | Left side</p>
				<Model
					data={pain_list}
					onClick={bodyPain}
					className="mb-3"
					type='anterior'
					bodyColor='#657C46'
					highlightedColors={['#dff9c4','#f5b357e6','#f55757']}
					/>
			
				
			</TabPanel>
		

			<TabPanel>

				<p> Left side | Right side</p>
				<Model
					data={pain_list}
					onClick={bodyPain}
					type='posterior'
					className="mb-3"
					bodyColor='#657C46'
					highlightedColors={['#dff9c4','#f5b357e6','#f55757']}
					/>

			
			
			</TabPanel>
					
		</Tabs>
						
		</Col>

			
			
			<Col lg="7" className='py-3'>
				<ListGroup className="mb-3 form-big" as="ul">
				{
				pain_list.map((item,index) => (
					<>
							<ListGroup.Item as="li"  key={index}  id={item.muscles[0]} >
							<Row>
								<Col className=" text-light">
								<h3 className='py-2 text-light'>{item.name}</h3>
								</Col>
								<Col className="text-end" xs="4" lg="3">
									<Button variant="danger" onClick={(elem)=>removeItem(item.muscles[0])} className='delete-button btn-sm'>				
										<img src={trash}  height="24" alt="Trash icon" />
									</Button>
								</Col>
							</Row>	
							<PatientPainEditable key={index} item={item}   />
							
							</ListGroup.Item>
					
				
					</>
				))
				}
				</ListGroup>
			</Col>
		</Row> 
		</Layout>	
	</>
  )
}

export default PatientConditionContent