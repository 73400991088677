import React, { useState, useEffect } from 'react'
import {Row,Col,Card, Spinner} from 'react-bootstrap'

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
  } from 'chart.js';
  
  import { Line } from 'react-chartjs-2';


  ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend
  );

  


  function TestLineChart( {title, data, x_axis, area, tension,limb,pointRadius}) {

	var color_left = 'rgba(155, 200, 230, 0.25)'
	var color_right = 'rgba(240, 160, 160, 0.25)'

	const [currentData, addData] = useState('')
	const [load,loadStatus] = useState('hide')

	
	const options = {

		responsive: true,
		scales: {
			x: {
			   grid: {
				  display: false
			   }
			},
			y: {
			   grid: {
				  display: false
			   }
			}
	   },
		plugins: {
			
		  legend: {
			labels: {
				usePointStyle: true,
			  },
			position: 'bottom',
		  },
		  title: {
			display: true,
			text: title,
		  },
		  
		  
		},
	  };


	const labels = data.Left.x[x_axis];

  
	useEffect(() => {
		loadStatus('');
		

		const loadedData = {
			labels,
			datasets: []
		  };

		  if (data) {
			if (limb==='left' || limb==="both") {
				for (const elem in data.Left.y) {
					let structure = {
						label: "Left " + elem,
						data: data.Left.y[elem],
						fill: area, 
						pointRadius: pointRadius,
						backgroundColor: color_left, 
						borderColor: color_left,
						pointBorderColor: color_left,
						pointBackgroundColor: color_left,
						tension: tension,
					};
					loadedData.datasets.push(structure);
					//console.log(structure);
				  }		  
			}
			if (limb==='right' || limb==="both") {
				for (const elem in data.Right.y) {
					let structure = {
						label: "Right " + elem,
						data: data.Right.y[elem],
						fill: area, 
						pointRadius: pointRadius,
						backgroundColor: color_right, 
						borderColor: color_right,
						pointBorderColor: color_right,
						pointBackgroundColor: color_right,
						tension: tension,
					};
					loadedData.datasets.push(structure);
					//console.log(structure);
				}		  
			}
		}
		addData(loadedData);
		loadStatus('hide')
		setTimeout(	
			function(){
			}
			,300
		);
	
	  return () => {
		//second
	  }
	}, [area,color_left,color_right,data,labels,limb,pointRadius,tension])
	
	

	if (currentData === '') {
		return (
			<Row>
			<Col>
				<div className="alert">
					No data
				</div>
			<Spinner animation="border" className={load + ' ms-3'} role="status">
				<span className="visually-hidden">Loading...</span>
			</Spinner>	
			</Col>
		</Row>)
	}

	
  return (
	<>
	<Row>
		<Col>
			<Card bg="light">
				<Card.Body>
				{JSON.stringify(title,pointRadius)}
					<Line options={options} id="21" data={currentData} />
				</Card.Body>
			</Card>
		</Col>
	</Row>		
	</>	

  )
}

export default TestLineChart