import React, { useState } from 'react'
import icon from '../Modules/Common/img/icon-bluetooth.svg';
import {Navbar} from 'react-bootstrap';
import Clock from 'react-live-clock';
import logo from '../Modules/Common/img/logo-g.png';
import { Link } from 'react-router-dom';


const Header = ({bluetooth}) => {

	const [status, setStatus] = useState('disabled')

	if (bluetooth === 1) {
		setStatus('active')
	}

  return (
	<div className='w-100 header border-bottom'>
		<Navbar variant="dark" expand='xs' className='py-0' >

			<Link to="/">
				<img src={logo} className="App-logo img-fluid"  alt="Pendulum logo" />
			</Link>
			

			
			
			<p className='m-0'>
				<Clock format={'HH:mm:ss'} ticking={true}  />
			</p>

			<img src={icon} className={status} height="24" alt="Bluetooth" />

			
		</Navbar>
	</div>

  )
}

export default Header