import React, { useCallback, useEffect, useState } from 'react'
import CheckboxGroup from "../Common/Checkbox";
import CommentBox from '../Common/CommentBox';
import { Form, Col, Row} from "react-bootstrap"


function PageSubmit({title, subtitle, source_data, data, updateData}) {
	// useState variables update on changes to each component and contains data to submit
	const [check_data, setCheckData] = useState([]);
	const [comment_data, setCommentData] = useState("");

// Checks which checkboxes are selected 
	const [checkedState2, setCheckedState2] = useState(new Array(source_data.length).fill(false));

// Prepares data to submit, add each new row with a useState variable 
	const handleDataUpdate = useCallback(() => {
		const updatedFormValues = {
		  ...data,
		  assessment: title,
		  comment: comment_data,
		  check: check_data,
		};
		updateData(updatedFormValues);
	  }, [data,updateData, title, comment_data, check_data]);
	
	  useEffect(() => {
		handleDataUpdate();
	  }, [handleDataUpdate]);
	


  return (
	<>
	<Row>
		<Col lg={7}>
		<h2>{title}</h2>
		<p className="lead">
			{subtitle}
		</p>
		</Col>
	</Row>
	<Row>
		<Col lg="6">
			<Form className=' form-multipage form-big p-0'>
				<CheckboxGroup
					label="Conditions"
					options={source_data}
					checkedState={checkedState2}
					setCheckedState={setCheckedState2}
					setType={setCheckData}
					setState={setCheckData}
					/>

			</Form>
		</Col>
		<Col>
			<CommentBox onCommentChange={(e)=>setCommentData(e)} />
		</Col>
	</Row>
	</>
  )
}

export default PageSubmit