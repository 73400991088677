import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Home from '../Pages/Home'
import AllPatients from '../Pages/AllPatients'
import PatientNew from '../Pages/PatientCreateNew'
import Patient from '../Pages/PatientProfile'
import Pain from '../Modules/Assessment/PatientPainContent'
import InitialNonSpecific from '../Modules/Assessment/InitialNonSpecific'
import InitialSpecific from '../Modules/Assessment/InitialSpecific'
import InitialSensorimotor from '../Modules/Assessment/InitialSensorimotor'
import InitialSensorimotorChild from '../Modules/Assessment/InitialSensorimotorChild'
import TUG from '../Modules/Assessment/PatientTUG'
import Chair from '../Modules/Assessment/PatientChair'

import {Container} from 'react-bootstrap'
import TestResults from '../Modules/Assessment/TestResults'


function Whole() {


  return (
	<> 
		<Container fluid className="dark-mode h-100">
			<Routes>
			

				<Route index="/"  element={<Home/>} />
				<Route path="/physiotherapy" element={<AllPatients/>} />

				<Route path="/physiotherapy/:patientId" element={<Patient/>} />
				<Route path="/new-patient" element={<PatientNew/>} />
				<Route path="/physiotherapy/:patientId/pain" element={<Pain/>} />
				<Route path="/physiotherapy/:patientId/non-specific" element={<InitialNonSpecific/>} />
				<Route path="/physiotherapy/:patientId/specific" element={<InitialSpecific/>} />
				
				<Route path="/physiotherapy/:patientId/sensorimotor" element={<InitialSensorimotor/>} />

				<Route path="/physiotherapy/:patientId/test/:testId" element={<InitialSensorimotorChild/>} />

				<Route path="/physiotherapy/:patientId/results/:testId" element={<TestResults/>} />

				<Route path="/physiotherapy/:patientId/chair" element={<Chair/>} />
				<Route path="/physiotherapy/:patientId/tug" element={<TUG/>} />
			</Routes>
		</Container>
	</>
		
  )
}

export default Whole