import React, {useState,useEffect} from 'react'
import { useParams } from "react-router-dom";


import Layout from '../../Core/Layout'
import VideoLoader from '../Common/VideoLoader'

import { Col, Row} from "react-bootstrap"


import { getDoc, doc } from 'firebase/firestore';
import {db} from '../../firebase';





function InitialSensorimotor() {
	const { patientId } = useParams();
	const { testId } = useParams();

	const sidebarContent = {
	  title: "Initial Assessment",
	  parent: "All Patients",
	  parent_url: `/physiotherapy/${patientId}`,
	  menu: "assessment",
	};
  
	const [sourceData, setSourceData] = useState([]);
	
  
	useEffect(() => {
	  const fetchPost = async () => {
		

		const docRef = doc(db, 'group-tests', testId);
		  const docSnapshot = await getDoc(docRef);
		  if (docSnapshot.exists()) {
			const data = docSnapshot.data();
			setSourceData(data);
		  } else {
			console.log(`Exercise with ID ${testId} does not exist.`);
		  }

	  
		
	  };
  
	  fetchPost();
	}, []);
  


	
	
	  
	const title = "Sensorimotor Analysis"
	const subtitle ="Select tests to gather patient data"
  
	return (
	<Layout sidebarContent={sidebarContent} defaultkey="1">
	

		<Row>
			<Col >
			<h2>Sensorimotor Analysis</h2>
			<p className="lead">
				Select tests to gather patient data
			</p>
				<VideoLoader
					test="rom"
					options={sourceData}
					patientId={patientId}
					testId={testId}
				/>
			</Col>
		</Row>
	
	</Layout>
  )
}

export default InitialSensorimotor