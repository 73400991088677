import { createStore } from 'redux';

const initialState = {
  userType: null
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_VARIABLE':
      return { ...state, userType: action.payload };
    default:
      return state;
  }
}

const store = createStore(reducer);

export default store; // export the store variable
