import React, {useState} from 'react'
import CheckboxGroup from "../Common/Checkbox";

import {Row,Col,Button,Form, Spinner} from 'react-bootstrap'

import left from '../Common/img/icon-arrow-left.svg'
import right from '../Common/img/icon-arrow-right.svg'
import send from '../Common/img/icon-save-fill.svg'

import { collection, addDoc } from "firebase/firestore";
import {db} from '../../firebase';


import DatePicker from "react-datepicker";


function PatientPainEditableSource({item, saveItem, pain_content, setPainContent, setEditable}) {

const [startDate, setStartDate] = useState(new Date());

const [intensity, setIntensity] = useState(item.frequency);
	
const [pain_frequency, setFrequency] = useState([]);
	
const [pain_type, setPainType] = useState([]);



	const pain_types_source =  ['sharp','stabbing','numbness','other', 'constant', 'intermittent'];
	const pain_frequency_source =  ['degenerative','referred','inflammatory','infective','trauma','neoplastic','psychogenic','congenital'];

	const [checkedState1, setCheckedState1] = useState(new Array(pain_types_source.length).fill(false));

	const [checkedState2, setCheckedState2] = useState(new Array(pain_frequency_source.length).fill(false));


const [loading, setLoading] = useState(false);
//const [editing, setEditing] = useState(false);




// Pages in form 

const [page, setPage] = useState(1);
	
const handleNextPage = () => setPage(page + 1);
const handlePrevPage = () => setPage(page - 1);

/*
const handleSubmit = () => {
  // submit form data to the server
};
*/

  const handleSubmit = async (e) => {
		console.log(e)
		setEditable(false)

		setPainContent({
			intensity: intensity,
			type: pain_type,
			frequency: pain_frequency,
		})
		
		e.preventDefault();  
	
		try {
			setLoading(true);
	
			const docRef = await addDoc(collection(db, "pain"), {
				intensity: intensity,
				type: pain_type,
				frequency: pain_frequency,
					
			});
			console.log("Document written with ID: ", docRef.id);
			setLoading(false);
	
		  } catch (e) {
			console.error("Error adding document: ", e);
		  }
		  
	  }
	

  


  return (
	
	<>
			<Row>
			<Col>
			<Form className=' form-multipage form-big p-0'>
			{page === 1 && (
			<>
			<Form.Label className='w-100'>Pain intensity</Form.Label>
			<Form.Group className="input-group">

					{['1','2','3'].map((type,index) => (
					
					<Form.Check 
						inline
						type="radio"
						name="pain"
						id={`default-${type}`}
						key={`default-${index}`}
						label={`${type} `}
						onClick={() => setIntensity(`${type} `)}
						checked={type === item.frequency.toString()}
					/>

					
				))}

			</Form.Group>

			<Form.Label>Started</Form.Label>
			<Form.Group  className="input-group">
				<DatePicker className='mb-3 form-control' selected={startDate} 
				onChange={(date) => setStartDate(date)} 
				/>

			</Form.Group>

			</>
			)}


			{page === 2 && (
				<>
			<CheckboxGroup
				label="Pain Type"
				options={pain_types_source}
				checkedState={checkedState1}
				setCheckedState={setCheckedState1}
				type={pain_type}
				setType={setPainType}
				setState={setPainType}
				/>
			</>
			)}

			{page === 3 && (
				<>
			<CheckboxGroup
				label="Pain Frequency"
				options={pain_frequency_source}
				checkedState={checkedState2}
				setCheckedState={setCheckedState2}
				type={pain_type}
				setType={setFrequency}
				setState={setFrequency}
				/>
			</>
			)}
			</Form>
			</Col>
			
		</Row>
		<Row className='mt-5 w-100'>
				<Col className='text-end'>
					<Button variant="outline-light" disabled={page === 1 ? 'disabled':''} type="button" onClick={handlePrevPage}><img src={left}  height="24" alt="Left arrow" /> Prev</Button>{' '}
				</Col>


				{page === 3 ? 

				// TODO specify last page here 
				
				(
					<Col className='text-start'>
							<Button disabled={loading? 'disabled' :''} variant="outline-light" className="me-3" type="button" 
							onClick={handleSubmit}
							
							><img src={send}  height="24" alt="Send icon" /> Save  
					</Button>

					{loading ? 
					<Spinner animation="border" role="status">
						<span className="visually-hidden">Loading...</span>
					</Spinner>
					: ''}
					</Col>
					) 
				:
				(
					<Col className='text-start'>
						<Button variant="outline-light" type="button" onClick={handleNextPage}>Next <img src={right}  height="24" alt="Right arrow" /></Button>
					</Col>
					) 
				}
		</Row>	
	</>

	)
}

export default PatientPainEditableSource