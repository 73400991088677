import React, { useState, useEffect } from "react";
import PatientCard from '../Modules/Common/PatientCard'

import Layout from '../Core/Layout'
import { useParams } from "react-router-dom";

import {Row, Col} from 'react-bootstrap'

import { doc, getDoc } from "firebase/firestore";
//import { Link } from "react-router-dom";
import {db} from '../firebase';
//import ExercisesRecommend from '../Exercises/ExercisesRecommend'

function Patient() {
	
	const { patientId} =  useParams();
	const [patient, setPatient] = useState([]);

	useEffect(() => {
		const fetchPatient = async () => {
		  const patientRef = doc(db, "patients", patientId);
		  const patientDoc = await getDoc(patientRef);
	
		  if (patientDoc.exists()) {
			setPatient({ id: patientDoc.id, ...patientDoc.data() });
		  } else {
			console.log("No such document!");
		  }
		};
	
		fetchPatient();
	  }, [patientId]);

	const sidebarContent = {
		'title':"Patient Profile", 
		'parent':"All Patients",
		'parent_url':"/physiotherapy",
		'button':"Assessment",
		'message':"Check Information",
		'button_url':`/physiotherapy/${patientId}/specific`
	}	  

  return (
	<Layout sidebarContent={sidebarContent} defaultkey="0">
		<section id="module-patient">
			<>
			<h2>
				Patient Profile 					
			</h2>
			<p class="lead">Patient Summary</p>
			<Row>
				<Col>
					<PatientCard patient={patient}></PatientCard>
				</Col>
				<Col>
				</Col>
			</Row>
			</>
		</section>
	</Layout>
	
  )
}

export default Patient