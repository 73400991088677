import React, {useState, useCallback, useMemo} from 'react'
import { useParams } from "react-router-dom";
import MultiPage from "../Common/MultiPage";
import Layout from '../../Core/Layout'

import PageSubmit from '../Common/PageSubmit';


function FirstPage({data,updateData}) {

	const source_data = [
		"Obesity", 
		"Diabetes",
		"H Blood Press",
		"Heart Disease",
		"Sleep Apnea",
		"Arthritis",
		"Asthma",
		"Depression"];

//PageSubmit creates a module with checkboxes on the left and a comment box in the right


	return (
		<>
			<PageSubmit data={data} source_data={source_data} updateData={updateData} title="Initial Specific" subtitle="Should it be examined?"/>
		</>
	)
}

function InitialSpecific() {

	const { patientId} =  useParams();

	const sidebarContent = {
	'title':"Initial Assessment", 
	'parent':`Patient ${patientId}`,
	'parent_url':`/physiotherapy/${patientId}`,
	'menu' : 'assessment',
	'button' : 'next',
	'user' : 'therapist',
	'button_url': `/physiotherapy/${patientId}/non-specific`
	}	  

	const [formValues, setFormValues] = useState({});
	
	const handleSliderChange = useCallback((newValues) => {
		setFormValues((prevValues) => ({ ...prevValues, ...newValues }));
	  }, []);


	  // Add pages by adding more items in views

	  const views = useMemo(
		() => [
		  {
			component: FirstPage,
			updateData: handleSliderChange
		  }
		],
		[ handleSliderChange]
	  );


  return (
	<Layout sidebarContent={sidebarContent} defaultkey="0">
		  <MultiPage 
          views={views} 
          submit={formValues} 
		  patientId={patientId}
		  db_name={"assessments"}
		  />

	</Layout>
  )
}

export default InitialSpecific