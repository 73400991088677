import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import Pose from '../MediaPipe/Pose'
import Chair from '../MediaPipe/Chair'
import TUG from '../MediaPipe/TUG'
import rec from "../Common/img/icon-record-fill.svg";

function VideoLoader({options, test, testId}) {

  const [showPose, setShowPose] = useState(false);


  return (
    <div>
    {showPose ? (
       <>
        <img src={rec} height="24" alt="Record" /> Recording...
       </>
      ) : (
        <PreVideo options={"rom"} />
      )}
      {
        test==="rom" ? <Pose onPoseReady={setShowPose} test={test} options={options} /> : ''
      }

{
        test==="tug" ? <TUG onPoseReady={setShowPose} /> : ''
      }

{
        test==="chair" ? <Chair onPoseReady={setShowPose} /> : ''
      }
      
     
    </div>
  );
}

function PreVideo({options}) {
  const [listItems, setListItems] = useState([]);

  const actions = ([ "Starting camera",
  "Loading Video...","Get ready"])

  useEffect(() => {
      let i = 0;
      const interval = setInterval(() => {
        setListItems(prevState => [...prevState, actions[i]]);
        i++;
        if (i >= actions.length) {
          clearInterval(interval);
        }
      }, 500);
      return () => clearInterval(interval);

  }, [options]);


  return (
    <>
      <div className="w-25 text-center py-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>

        <h4>
        {options}
        </h4>
      <ul>
        {listItems.map((item, index) => (
          <li
            key={index}
          >
            {item}
          </li>
        ))}
      </ul>
      </div>
        
       
    </>
  );
}
  


export default VideoLoader;
