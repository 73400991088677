// CheckboxGroup.js
import {Card,CloseButton,Button, Col, Row, InputGroup, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import play from '../Common/img/icon-play.svg'


const TestCards = ({ url, options }) => {
  
	

//  removes item from exercise cards


function removeItem (elem) {
	console.log(elem.target.offsetParent)
	var del = document.getElementById(elem.target.offsetParent.id);  
	del.classList.add('hide');
	
	setTimeout(function(){
		del.parentNode.removeChild(del);
	},600);
	
	
}
	

  return (
    <>

	<Row >
		<Col>
		<InputGroup className="mb-3 search">
        <Form.Control
          placeholder="Search"
          aria-label="Search"
          aria-describedby="basic-search"
        />
        <Button variant="outline-light" id="button-search">
          Search
        </Button>
      </InputGroup>
		</Col>
	</Row>
	<Row xs={1} md={2} lg={3}>

	
      {options.map((data, index) => (
	<Col className='mb-3'>
	
	<Card className="me-3 bg-light text-dark" id={data.name} key={data.name} >

		<CloseButton onClick={removeItem} className="rounded-circle btn-close align-self-end"/>
			{data.img? <img src={data.img} alt={data.title} className="card-img-top"/> : ''}			
			<Card.Body>

			<div id={"description_"+data.id} className="show">
				<h3 className="card-title">{data.name}</h3>
				{data.muscles? 
				<div className="card-text">
					<p>
						{data.description}
					</p>
					
					{data.muscles ? data.muscles.map((e, index)=>
					<span key={index} className="badge bg-secondary mx-1">
						{e}
					</span>
					) : ''
					}
				</div>
				:''
				}
				

			</div>
				
			</Card.Body>	
			<Card.Footer className="text-center">
					<Link to={url+"/"+data.id} className="btn btn-primary stretched-link rounded-circle">		
						<img src={play}  height="24" alt="Play icon" />
					</Link>
			</Card.Footer>
		</Card>

	  </Col>
        
      ))}
	</Row>
    </>
  );
};

export default TestCards;
