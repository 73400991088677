import { useRef } from 'react';
import Sidebar from './Sidebar';
import { Row, Col } from 'react-bootstrap';


function Layout({ children, sidebarContent, defaultkey }) {
	
	const containerRef = useRef(null);

	function scrollDown() {
		const container = containerRef.current;
		if (container) {
		  container.scrollTop = container.scrollHeight - container.clientHeight;
		}
	  }


  return (
	<>
		<Row className="layout h-100">		
			<Sidebar sidebarContent={sidebarContent} defaultkey={defaultkey}/>
			<Col  className=' h-100 position-relative overflow-hidden'>
				<div className="content " id="container" >
					{children}</div>
				<button className='d-none position-fixed bottom-0' onClick={scrollDown}>Scroll Down</button>
			</Col>
			
		</Row>
	</>
  );
}

export default Layout;
