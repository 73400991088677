import * as mediapipePose from "@mediapipe/pose";

export class JointsObject {
  JOINTS_LIST = [
    "heel",
    "hip",
    "knee",
    "elbow",
    "shoulder",
    "wrist",
    "thumb",
    "index",
    "pinky",
    "ankle",
    "footIndex",
    "ears",
    "mouth",
    "eyes",
    "innerEyes",
    "outerEyes",
    "nose",
  ];
  SIDE_LIST = ["left", "right"];

  constructor() {
    this.videoDim = {
      width: null,
      height: null,
    };
    this.fps = null;
    this.date = [];
    this.timestamp = [];

    this.position = {
      heel: {
        pose_left: mediapipePose.POSE_LANDMARKS_LEFT.LEFT_HEEL,
        pose_right: mediapipePose.POSE_LANDMARKS_RIGHT.RIGHT_HEEL,
        screen: {
          left: [],
          right: [],
        },
        real: {
          left: [],
          right: [],
        },
      },

      hip: {
        pose_left: mediapipePose.POSE_LANDMARKS_LEFT.LEFT_HIP,
        pose_right: mediapipePose.POSE_LANDMARKS_RIGHT.RIGHT_HIP,
        screen: {
          left: [],
          right: [],
        },
        real: {
          left: [],
          right: [],
        },
      },

      knee: {
        pose_left: mediapipePose.POSE_LANDMARKS_LEFT.LEFT_KNEE,
        pose_right: mediapipePose.POSE_LANDMARKS_RIGHT.RIGHT_KNEE,
        screen: {
          left: [],
          right: [],
        },
        real: {
          left: [],
          right: [],
        },
      },

      elbow: {
        pose_left: mediapipePose.POSE_LANDMARKS_LEFT.LEFT_ELBOW,
        pose_right: mediapipePose.POSE_LANDMARKS_RIGHT.RIGHT_ELBOW,
        screen: {
          left: [],
          right: [],
        },
        real: {
          left: [],
          right: [],
        },
      },

      shoulder: {
        pose_left: mediapipePose.POSE_LANDMARKS_LEFT.LEFT_SHOULDER,
        pose_right: mediapipePose.POSE_LANDMARKS_RIGHT.RIGHT_SHOULDER,
        screen: {
          left: [],
          right: [],
        },
        real: {
          left: [],
          right: [],
        },
      },

      wrist: {
        pose_left: mediapipePose.POSE_LANDMARKS_LEFT.LEFT_WRIST,
        pose_right: mediapipePose.POSE_LANDMARKS_RIGHT.RIGHT_WRIST,
        screen: {
          left: [],
          right: [],
        },
        real: {
          left: [],
          right: [],
        },
      },

      thumb: {
        pose_left: mediapipePose.POSE_LANDMARKS_LEFT.LEFT_THUMB,
        pose_right: mediapipePose.POSE_LANDMARKS_RIGHT.RIGHT_THUMB,
        screen: {
          left: [],
          right: [],
        },
        real: {
          left: [],
          right: [],
        },
      },

      index: {
        pose_left: mediapipePose.POSE_LANDMARKS_LEFT.LEFT_INDEX,
        pose_right: mediapipePose.POSE_LANDMARKS_RIGHT.RIGHT_INDEX,
        screen: {
          left: [],
          right: [],
        },
        real: {
          left: [],
          right: [],
        },
      },

      pinky: {
        pose_left: mediapipePose.POSE_LANDMARKS_LEFT.LEFT_PINKY,
        pose_right: mediapipePose.POSE_LANDMARKS_RIGHT.RIGHT_PINKY,
        screen: {
          left: [],
          right: [],
        },
        real: {
          left: [],
          right: [],
        },
      },

      ankle: {
        pose_left: mediapipePose.POSE_LANDMARKS_LEFT.LEFT_ANKLE,
        pose_right: mediapipePose.POSE_LANDMARKS_RIGHT.RIGHT_ANKLE,
        screen: {
          left: [],
          right: [],
        },
        real: {
          left: [],
          right: [],
        },
      },

      footIndex: {
        pose_left: mediapipePose.POSE_LANDMARKS_LEFT.LEFT_FOOT_INDEX,
        pose_right: mediapipePose.POSE_LANDMARKS_RIGHT.RIGHT_FOOT_INDEX,
        screen: {
          left: [],
          right: [],
        },
        real: {
          left: [],
          right: [],
        },
      },

      ears: {
        pose_left: mediapipePose.POSE_LANDMARKS_LEFT.LEFT_EAR,
        pose_right: mediapipePose.POSE_LANDMARKS_RIGHT.RIGHT_EAR,
        screen: {
          left: [],
          right: [],
        },
        real: {
          left: [],
          right: [],
        },
      },

      mouth: {
        // Manually selected numbers
        pose_left: 9,
        pose_right: 10,
        screen: {
          left: [],
          right: [],
        },
        real: {
          left: [],
          right: [],
        },
      },

      eyes: {
        pose_left: mediapipePose.POSE_LANDMARKS_LEFT.LEFT_EYE,
        pose_right: mediapipePose.POSE_LANDMARKS_RIGHT.RIGHT_EYE,
        screen: {
          left: [],
          right: [],
        },
        real: {
          left: [],
          right: [],
        },
      },

      innerEyes: {
        pose_left: mediapipePose.POSE_LANDMARKS_LEFT.LEFT_EYE_INNER,
        pose_right: mediapipePose.POSE_LANDMARKS_RIGHT.RIGHT_EYE_INNER,
        screen: {
          left: [],
          right: [],
        },
        real: {
          left: [],
          right: [],
        },
      },

      outerEyes: {
        pose_left: mediapipePose.POSE_LANDMARKS_LEFT.LEFT_EYE_OUTER,
        pose_right: mediapipePose.POSE_LANDMARKS_RIGHT.RIGHT_EYE_OUTER,
        screen: {
          left: [],
          right: [],
        },
        real: {
          left: [],
          right: [],
        },
      },

      nose: {
        pose: mediapipePose.POSE_LANDMARKS.NOSE,
        screen: [],
        real: [],
      },
    };
  }

  getLastScreenPosition(joint, side) {
    if (!this.JOINTS_LIST.includes(joint)) {
      console.log(`Error: "${joint}" does not exist!`);
    }
    if (!this.SIDE_LIST.includes(side)) {
      console.log(`Error: "${side}" does not exist!`);
    }
    let pos_all = this.position[joint]["screen"][side];
    let last_pos = pos_all[pos_all.length - 1];
    return [last_pos.x, last_pos.y];
  }

  getLastRealPosition(joint, side) {
    if (!this.JOINTS_LIST.includes(joint)) {
      console.log(`Error: "${joint}" does not exist!`);
    }
    if (!this.SIDE_LIST.includes(side)) {
      console.log(`Error: "${side}" does not exist!`);
    }
    let pos_all = this.position[joint]["real"][side];
    let last_pos = pos_all[pos_all.length - 1];
    return [last_pos.x, last_pos.y, last_pos.z];
  }

  getLastScreenNosePosition() {
    let pos_all = this.position["nose"]["screen"];
    let last_pos = pos_all[pos_all.length - 1];
    return [last_pos.x, last_pos.y];
  }

  getLastRealNosePosition() {
    let pos_all = this.position["nose"]["real"];
    let last_pos = pos_all[pos_all.length - 1];
    return [last_pos.x, last_pos.y, last_pos.z];
  }

  bodyInFrame() {
    const h = this.videoDim.height;
    const w = this.videoDim.width;

    let screenNose = this.getLastScreenNosePosition();
    let screenHeelL = this.getLastScreenPosition("heel", "left");
    let screenHeelR = this.getLastScreenPosition("heel", "right");

    let noseInFrameX = screenNose[0] < w && screenNose[0] > 0;
    let noseInFrameY = screenNose[1] < h && screenNose[1] > 0;

    let heelLInFrameX = screenHeelL[0] < w && screenHeelL[0] > 0;
    let heelLInFrameY = screenHeelL[1] < h && screenHeelL[1] > 0;

    let heelRInFrameX = screenHeelR[0] < w && screenHeelR[0] > 0;
    let heelRInFrameY = screenHeelR[1] < h && screenHeelR[1] > 0;

    if (
      noseInFrameX &&
      noseInFrameY &&
      heelLInFrameX &&
      heelLInFrameY &&
      heelRInFrameX &&
      heelRInFrameY
    )
      return true;
    return false;
  }

  getAllScreenPositions(joint, side) {
    let pos_all_object = this.position[joint]["screen"][side];
    let pos_all_list = [];
    for (let i = 0; i < pos_all_object.length; i++) {
      pos_all_list.push([pos_all_object[i].x, pos_all_object[i].y]);
    }
    return pos_all_list;
  }

  getAllRealPositions(joint, side) {
    let pos_all_object = this.position[joint]["real"][side];
    let pos_all_list = [];
    for (let i = 0; i < pos_all_object.length; i++) {
      pos_all_list.push([
        pos_all_object[i].x,
        pos_all_object[i].y,
        pos_all_object[i].z,
      ]);
    }
    return pos_all_list;
  }

  getAllScreenNosePositions() {
    let pos_all_object = this.position["nose"]["screen"];
    let pos_all_list = [];
    for (let i = 0; i < pos_all_object.length; i++) {
      pos_all_list.push([pos_all_object[i].x, pos_all_object[i].y]);
    }
    return pos_all_list;
  }

  getAllRealNosePositions() {
    let pos_all_object = this.position["nose"]["real"];
    let pos_all_list = [];
    for (let i = 0; i < pos_all_object.length; i++) {
      pos_all_list.push([
        pos_all_object[i].x,
        pos_all_object[i].y,
        pos_all_object[i].z,
      ]);
    }
    return pos_all_list;
  }
}
