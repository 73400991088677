import React, {useState,useEffect} from 'react'
import { useParams } from "react-router-dom";

import Layout from '../../Core/Layout'
import { Col, Row} from "react-bootstrap"

import { collection,getDocs } from "firebase/firestore";
import {db} from '../../firebase';
import TestCards from '../Common/TestsCards';




function InitialSensorimotor() {
	const { patientId } = useParams();


	const sidebarContent = {
		'title':"Initial Assessment", 
		'parent':`Patient ${patientId}`,
		'parent_url':`/physiotherapy/${patientId}`,
		'menu' : 'assessment',
		'patientId': patientId,
		'button' : 'next',
		'button_url': `/physiotherapy/${patientId}/sensorimotor`
	  }	  
  
	const [sourceData, setSourceData] = useState([]);


	  useEffect(()=>{

		const fetchPost = async () => {
	
			await getDocs(collection(db, "group-tests"))
				.then((querySnapshot)=>{               
					const newData = querySnapshot.docs
						.map((doc) => ({...doc.data(), id:doc.id }));
					setSourceData(newData);                
				})
		
		}
  
	  fetchPost();
	}, []);
  
	
	  
	const title = "Sensorimotor Analysis"
	const subtitle ="Select tests to gather patient data"
  
	return (
	<Layout sidebarContent={sidebarContent} defaultkey="1">
	

		<Row>
			<Col >
			<h2>{title}</h2>
			<p className="lead">
				{subtitle}
			</p>			
			<TestCards
				options={sourceData} 
				url={sidebarContent.parent_url+"/test"}
				
			/>
			</Col>			
		</Row>
	
	</Layout>
  )
}

export default InitialSensorimotor