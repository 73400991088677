import React from 'react'
import {Nav} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { NavLink } from 'react-router-dom';


function Navigation({defaultkey, data, menu}) {
  
if (menu==='therapist') {
	return (
	<>
	<h3>{data.menu}</h3>
    <Accordion defaultActiveKey={defaultkey} className='nav-menu w-100'>
      <Accordion.Item eventKey="0">
        <Accordion.Header> 1. Preliminary examination</Accordion.Header>
        <Accordion.Body>
			<Nav className='ms-3 nav flex-column'>
				<Nav.Item>
					classification I & II
					<Nav defaultActiveKey="/home" className="flex-column border-start px-3 mb-2">
						<Nav.Item>
							<NavLink  to={data.parent_url+"/specific"}>specific</NavLink>
						</Nav.Item>
						<Nav.Item >
							<NavLink  to={data.parent_url+"/non-specific"}>non-specific</NavLink>
						</Nav.Item>
					</Nav>
				</Nav.Item>
				<Nav.Item>
					Pain localization
					<Nav className="flex-column border-start px-3">
						<Nav.Item >
							<NavLink  to={data.parent_url+"/pain"}>area</NavLink>
						</Nav.Item>
						<Nav.Item>
							direction
						</Nav.Item>
					</Nav>
				</Nav.Item>
			</Nav>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>2. Specific examination</Accordion.Header>
        <Accordion.Body>
			<Nav className='ms-3 flex-column'>
				<Nav.Item>
					Physical examination
					<Nav className="flex-column border-start px-3 mb-2">
					<Nav.Item >
						Neurological
					</Nav.Item>
					<Nav.Item >
						Stability
					</Nav.Item>

					<Nav.Item >
						<NavLink  to={data.parent_url+"/tug"}>TUG</NavLink>
					</Nav.Item>
						<Nav.Item >
						<NavLink  to={data.parent_url+"/chair"}>Chair</NavLink>
					</Nav.Item>
					<Nav.Item>
					<NavLink  to={data.parent_url+"/sensorimotor"}>Sensorimotor tests</NavLink>
					</Nav.Item>
					</Nav>
				</Nav.Item>
				<Nav.Item className='w-100'>
					External Factors
				</Nav.Item>
			</Nav>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
	</>
  )
}

 
if (menu==='physio') {
	return (
	<>
 
			<Nav className='ms-3 nav flex-column'>
				<Nav.Item>
					<NavLink  to={data.parent_url+"/sensorimotor"}> ROM</NavLink>
				</Nav.Item>
			</Nav>
        
	</>
  )
}



if (menu==='elder') {
	return (
	<>
 
 			<Nav className='ms-3 nav flex-column'>
				<Nav.Item>
					<NavLink  to={data.parent_url+"/sensorimotor"}> Tests</NavLink>
				</Nav.Item>
			</Nav>
        
	</>
  )
}

}

export default Navigation