import React, { useState, useCallback, useMemo }  from "react";
import MultiPage from "../Modules/Common/MultiPage";
import Layout from '../Core/Layout';
import { Form, Button,  ButtonGroup } from 'react-bootstrap';



//import send from '../Common/img/icon-save-fill.svg'

function FirstPage({ updateData, data}) {
  const [formData, setFormData] = useState(data);

  const handleChange = (e) => {
    const { id, value } = e.target;
    const updatedFormData = {
      ...data,
      [id]: value
    };
    setFormData(updatedFormData);
    updateData(updatedFormData);
  };

  
  
  return (
    <div className='p-3 form-multipage form-big text-center '>

        <h2>About patient</h2>
        
        <p className="lead">
          Personal details
        </p>

        

          <Form.Group className="input-group" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" 
             defaultValue={formData.name}
            placeholder="Enter name"  onChange={handleChange} />
          </Form.Group>

		      <Form.Group className="input-group" controlId="surname">
            <Form.Label>Surname</Form.Label>
            <Form.Control type="text" placeholder="Enter surname"  
            defaultValue={formData.surname}
            onChange={handleChange} />
          </Form.Group>

		      <Form.Group className="input-group" controlId="insurance">
            <Form.Label>Insurance</Form.Label>
            <Form.Control type="text" placeholder="Enter Insurance Type"  
            defaultValue={formData.insurance}
            onChange={handleChange} />
          </Form.Group>
		  
          <Form.Group className="input-group" controlId="gender">
            <Form.Label>Gender</Form.Label>
            <Form.Control type="text" placeholder="Enter Gender AB"  
            defaultValue={formData.gender}
            onChange={handleChange} />
          </Form.Group>
          
          <Form.Group className="input-group" controlId="pronouns">
            <Form.Label>Pronouns</Form.Label>
            <Form.Control type="text" 
            placeholder="Enter Pronouns"  
            defaultValue={formData.pronouns}
            onChange={handleChange} />
          </Form.Group>


     
    </div>
  );
}

function SecondPage({ updateData, data}) {

  const [formData, setFormData] = useState(data);

  const handleChange = (e) => {
    const { id, value } = e.target;
    const updatedFormData = {
      ...data,
      [id]: value
    };
    setFormData(updatedFormData);
    updateData(updatedFormData);
  };


  const handleDirectionChange = dir => {
    setHand(dir);
  }
  const [handedness, setHand] = useState('');
  return (
    <div className='p-3 form-multipage form-big text-center '>
     
          <h2>About patient's body</h2>
     
          <p className="lead">
            Please provide data
          </p>

          <Form.Group className="input-group " controlId="age">
                  <Form.Label >Age</Form.Label>
                  <Form.Control className='em-6' type="text" placeholder="Enter Age"  
                  defaultValue={formData.age}
                  onChange={handleChange} />
            <Form.Text className='input-group-text'>y.o.</Form.Text>
              </Form.Group>

          <Form.Group className="input-group " controlId="weight">
                  <Form.Label >Weight</Form.Label>
                  <Form.Control className='em-6'  type="text" placeholder="Enter weight"  
                  defaultValue={formData.weight}
                  onChange={handleChange} />
            <Form.Text className='input-group-text'>kg</Form.Text>
              </Form.Group>


          <Form.Group className="input-group" controlId="height">
                  <Form.Label >Height</Form.Label>
                  <Form.Control  className='em-6'  type="text" placeholder="Enter height"  
                  defaultValue={formData.height}
                  onChange={handleChange} />
            <Form.Text className='input-group-text'>cm</Form.Text>
              </Form.Group>

          <Form.Group className="input-group " controlId="handedness">
                  <Form.Label >Handedness</Form.Label>
                  
            <ButtonGroup>
            <Button variant={handedness === 'left' ? 'light' : 'outline-primary'} onClick={() => handleDirectionChange('left')}>
              Left
            </Button>
            <Button variant={handedness === 'right' ? 'light' : 'outline-primary'} onClick={() => handleDirectionChange('right')}>
              Right
            </Button>
            </ButtonGroup>
              </Form.Group>
            
    </div>
  );
}

function ThirdPage({ updateData, data }) {

  const [formData, setFormData] = useState(data);

  const handleChange = (e) => {
    const { id, value } = e.target;
    const updatedFormData = {
      ...data,
      [id]: value
    };
    setFormData(updatedFormData);
    updateData(updatedFormData);
  };



  
  return (
    <div className='p-3 form-multipage form-big text-center '>
      
		   <h2>Contact details</h2>
			<p className="lead">
				Edit and save
			</p>

        <Form.Group className="input-group "  controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email"  
            defaultValue={formData.email}
            onChange={handleChange} />
        </Form.Group>

        <Form.Group className="input-group "  controlId="img">
            <Form.Label>Image</Form.Label>
            <Form.Control placeholder="url" 
            defaultValue={formData.img}
            onChange={handleChange} />
          </Form.Group>
    </div>
  );
}
function App() {


  const [formValues, setFormValues] = useState({});
	
	const handleSliderChange = useCallback((newValues) => {
		setFormValues((prevValues) => ({ ...prevValues, ...newValues }));
	  }, []);

  const sidebarContent = {
  'title':"New Patient", 
  'parent':"All Patients",
  'parent_url':"/physiotherapy",
  'button':"Cancel",
  'button_url':'/physiotherapy'
}	  

const views = useMemo(
  () => [
      {
      component: FirstPage,
      updateData: handleSliderChange,
      data: formValues
      },
     {
      component: SecondPage,
      updateData: handleSliderChange,
     data: formValues 
      },
      {
        component: ThirdPage,
        updateData: handleSliderChange,
        data: formValues 
        }
  ],
  [ handleSliderChange, formValues]
  );

  return (
  <>
    <Layout sidebarContent={sidebarContent}>
    <MultiPage 
      views={views} 
      submit={formValues} 
		  db_name={"patients"}
		  />
    </Layout>
  </>)
}

export default App;
