import { JointsObject } from "./JointsObjects";
import { detectLocalExtrema } from "./utils";

export class MediapipeAccum {
  MAX_SAMPLE_LENGTH = 2000;
  constructor() {
    this.fps = null;
    this._constructor();
  }

  _constructor() {
    this.n = 0;
    this.loggedOneSample = false;
    // this._resetFlag = false;
    this._startTime = null;
    this._endTime = null;
    this.joints = new JointsObject();
    // Always use fps calculated at the beginning
    this.fps = this.fps !== null ? this.fps : null;
    this.joints.fps = this.fps;
  }

  setVideoDimensions(width, height) {
    if (
      this.joints.videoDim.width === null ||
      this.joints.videoDim.height === null
    ) {
      this.joints.videoDim.width = width;
      this.joints.videoDim.height = height;
      console.log(
        "Video dimensions:",
        this.joints.videoDim.width,
        this.joints.videoDim.height
      );
    }
  }

  setLandmarks(landmarks2d, landmarks3d) {
    this._landmarks2d = landmarks2d;
    this._landmarks3d = landmarks3d;
  }

  accumulate() {
    this._setFps();

    // Iterate through a list of joints
    for (let i = 0; i < this.joints.JOINTS_LIST.length; i++) {
      this._pushCoordinates(this.joints.JOINTS_LIST[i]);
    }
    this._pushTime();
    this._setLoggedOneSample();
    this._increaseCounter();
    this._accumCsv();

    // Reset the arrays when they have to many samples
    if (this.n > this.MAX_SAMPLE_LENGTH) this._reset();

    if (this.n % 100 === 0) {
      console.log(this.joints);
      console.log(`Body in frame: ${this.joints.bodyInFrame()}`);

      // let pos1 = this.joints.get_last_screen_position("shoulder", "right");
      // let pos2 = this.joints.get_last_real_position("shoulder", "right");
      // console.log(pos1, pos2);
      // let ang = angleBetweenVectors(
      //   [0.0, 0.0],
      //   [1.0, 1.0],
      //   [1.0, 1.0],
      //   [2.0, 2.0]
      // );
      // console.log(ang);
    }
  }

  _setFps() {
    const NUM_SAMPLES = 100;

    if (this.fps === null) {
      if (this._startTime === null) this._startTime = Date.now();

      if (this.n === NUM_SAMPLES) {
        this._endTime = Date.now();
        const time_elapsed = (this._endTime - this._startTime) / 1000;

        this.fps = Math.round(NUM_SAMPLES / time_elapsed);
        this.joints.fps = this.fps;
      }
    }
    if (this.n === NUM_SAMPLES) {
      console.log(`FPS: ${this.joints.fps}`);
    }
  }

  _pushTime() {
    this.joints.date = new Date().toJSON();
    this.joints.timestamp.push((Date.now() - this._startTime) / 1000);
  }

  _pushCoordinates(joint) {
    if (joint !== "nose") {
      let screen_left = this._landmarks2d[
        this.joints.position[joint].pose_left
      ];
      let screen_right = this._landmarks2d[
        this.joints.position[joint].pose_right
      ];
      let real_left = this._landmarks3d[this.joints.position[joint].pose_left];
      let real_right = this._landmarks3d[
        this.joints.position[joint].pose_right
      ];

      this.joints.position[joint].screen.left.push(this._norm2pix(screen_left));
      this.joints.position[joint].screen.right.push(
        this._norm2pix(screen_right)
      );
      this.joints.position[joint].real.left.push(real_left);
      this.joints.position[joint].real.right.push(real_right);
    } else {
      let screen = this._landmarks2d[this.joints.position[joint].pose];
      let real = this._landmarks3d[this.joints.position[joint].pose];

      this.joints.position[joint].screen.push(this._norm2pix(screen));
      this.joints.position[joint].real.push(real);
    }
  }

  _setLoggedOneSample() {
    if (!this.loggedOneSample) this.loggedOneSample = true;
  }

  _increaseCounter() {
    this.n++;
  }

  _reset() {
    this._constructor();
  }

  _norm2pix(landmarkObject) {
    let result = { ...landmarkObject };
    result.x = Math.round(result.x * this.joints.videoDim.width);
    result.y = Math.round(result.y * this.joints.videoDim.height);
    return result;
  }

  _accumCsv() {
    // TODO
  }
}
