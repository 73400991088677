import React, { useState } from "react";
import {  Button, Row, Col } from 'react-bootstrap';


import left from '../Common/img/icon-arrow-left.svg'
import right from '../Common/img/icon-arrow-right.svg'

import FirebaseSubmit from "./FirebaseSubmit";

// This module creates multiple pages, adds navigation and invokes the submit function 

function Slider({ views, onChange, submit, patientId, db_name }) {

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const isFirstPage = currentPageIndex === 0;
  const isLastPage = currentPageIndex === views.length - 1;

  const handlePreviousClick = () => {
    if (!isFirstPage) {
      setCurrentPageIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (!isLastPage) {
      setCurrentPageIndex((prevIndex) => prevIndex + 1);
    }
  };


	const handleViewChange = (newValues) => {
		const updatedViews = [...views];
		updatedViews[currentPageIndex] = {
			...updatedViews[currentPageIndex],
			...newValues,
		};
		onChange(updatedViews);
	};

	const CurrentView = views[currentPageIndex].component;
	

  return (
	<>
	
	
	<div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ flex: "1", overflowX: "hidden",
	  	// TODO: hard coded width to avoid Layout from breaking, fix later on
	 	 maxWidth:"900px" 
	  }}>
        <div
            style={{
              display: "flex",
              width: `${views.length * 100}%`,
              transform: `translateX(-${
                currentPageIndex * (100 / views.length)
              }%)`,
              transition: "transform 0.5s ease-in-out",
            }}
          >
		



		{	views.map((view, index) => {
				return (
					<div
					key={index}
					style={{
						width: `${100 / views.length}%`,
						alignItems: "center",
						justifyContent: "center",
					}}
					>
					 <CurrentView {...views[currentPageIndex]} onChange={handleViewChange} />
					</div>
				);
				})}

			
        </div>
      </div>
	  
      <Row className='w-100 position-absolute bottom-0'>
		<Col className='text-end'>
			<Button variant="outline-light" 
			type="button" 
			onClick={handlePreviousClick}
            disabled={isFirstPage}
			><img src={left}  height="24" alt="Left arrow" /> Prev</Button>{' '}
		</Col>

		<Col className='text-start'>
			<Button variant="outline-light" type="button"  
	        onClick={handleNextClick}
			disabled={isLastPage}
			>Next <img src={right}  height="24" alt="Right arrow" /></Button>
		</Col>
		<Col>
			<div>
				{currentPageIndex + 1} of {views.length}
			</div>
		</Col>
		
		<Col className='text-start'>
				<FirebaseSubmit data={submit} db_name={db_name? db_name : "testing"} patientId={patientId} />
		</Col>
	  </Row>
    </div>
			
	</>

  );
}

export default Slider;
