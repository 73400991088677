// CheckboxGroup.js
import { Form } from "react-bootstrap";

const CheckboxGroup = ({ label, options, checkedState, setCheckedState, type, setType, setState }) => {
  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);

    const updatedTypes = updatedCheckedState.reduce(
      (acc, item, index) =>
        item ? [...acc, options[index]] : acc.filter((t) => t !== options[index]),
      []
    );
    setType(updatedTypes);

    setState(updatedTypes);
  };

  return (
    <Form.Group>
      <Form.Label className="w-100">{label}</Form.Label>
      {options.map((option, index) => (
        <Form.Check 
          inline
          id={option}
          key={option}
          label={option}
          checked={checkedState[index]}
          onChange={() => handleOnChange(index, option)}
        />
      ))}
    </Form.Group>
  );
};

export default CheckboxGroup;
