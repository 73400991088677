import { useEffect, useRef, useState } from "react";
//import { useEffect, useRef } from "react";
//import {Pose} from '@mediapipe/pose'
//import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";
//import * as mediapipePose from "@mediapipe/pose";
//const [repCounter, setRepCounter] = useState(null)
import {
  drawConnectors,
  drawLandmarks,
} from "@mediapipe/drawing_utils/drawing_utils";
import Webcam from "react-webcam";
import * as cam from "@mediapipe/camera_utils";
import * as mediapipePose from "@mediapipe/pose";
import { Pose } from "@mediapipe/pose";
import { Form } from "react-bootstrap";
import { isThisMonth } from "date-fns";
import { MediapipeAccum } from "./Packages/MediapipeAccum";
import { ComputeParam } from "./Packages/ComputeParam";
import { TugApp } from "./Packages/ChairTests/TugApp";

//import { mediapipePose } from "@mediapipe/pose/pose";
/*
Source:
https://github.com/google/mediapipe/issues/3042

Live code:
https://codepen.io/mediapipe/pen/jOMbvxw

Contructor issue: 
https://github.com/google/mediapipe/issues/1719
https://github.com/google/mediapipe/issues/1976

Google reply: 
https://github.com/google/mediapipe/issues/1408

JS gpt reply: 
https://github.com/google/mediapipe/issues/1411

https://cdn.jsdelivr.net/npm/@mediapipe/camera_utils/index.d.ts
https://cdn.jsdelivr.net/npm/@mediapipe/control_utils/index.d.ts
https://cdn.jsdelivr.net/npm/@mediapipe/drawing_utils/index.d.ts
https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/index.d.ts
https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/index.d.ts
https://cdn.jsdelivr.net/npm/@mediapipe/pose/index.d.ts
https://cdn.jsdelivr.net/npm/@mediapipe/hands/index.d.ts
https://cdn.jsdelivr.net/npm/@mediapipe/holistic/index.d.ts

import {
  drawConnectors,
  drawLandmarks,
} from '@mediapipe/drawing_utils/drawing_utils';
import { Webcam } from '@mediapipe/camera_utils/camera_utils';
import { Pose } from '@mediapipe/pose/pose';
*/

// Setting instances of classes
const mediapipeAccum = new MediapipeAccum();
const computeParam = new ComputeParam(mediapipeAccum);
const tugApp = new TugApp(mediapipeAccum, computeParam);

const UserPose = ({ onPoseReady }) => {
  // * refs to the html elements
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  // const landmarkRef = useRef(null);
  let userPoseAngle = null;
  let camera = null;
  let down;
  let repsCounter = 0;

  // checks if MediaPipe is gathering data
  const [isPoseReady, setIsPoseReady] = useState(false);

  // State for sitting detection
  const [subjectSitting, setSubjectSitting] = useState(false);
  const [stopwatch, setStopwatch] = useState(0.0);

  // * function to draw the landmarks once the pose has been determined
  // * the application is run in this function
  function onResults(results, index) {
    let landmarks2D = results.poseLandmarks; // * all the 2D landmarks in the pose
    let landmarks3D = results.poseWorldLandmarks; // * all the 3D landmarks in the pose
    //  * getting the values for the three landmarks that we want to use
    try {
      // * we get errors every time the landmarks are not available
      // * will provide dynamic landmarks later "landmarks[mediapipePose.POSE_LANDMARKS.{landmark}]"

      mediapipeAccum.setVideoDimensions(
        webcamRef.current.video.videoWidth,
        webcamRef.current.video.videoHeight
      );
      mediapipeAccum.setLandmarks(landmarks2D, landmarks3D);
      mediapipeAccum.accumulate();

      if (mediapipeAccum.loggedOneSample) {
        computeParam.main();
        tugApp.main();
        // * setting states to display on frontend
        setSubjectSitting(tugApp.startSittingDetected);
        setStopwatch(tugApp.stopwatch);
      }
    } catch (error) {
      //   console.error(error);
    }
    // Define the canvas element dimensions using the earlier created refs
    drawOnCanvas(canvasRef, webcamRef, results);

    // checks if camera has been started to trigger
    if (!isPoseReady) {
      onPoseReady(true);
      setIsPoseReady(true);
    }
  }

  function drawOnCanvas(canvasRef, webcamRef, results) {
    canvasRef.current.width = webcamRef.current.video.videoWidth;
    canvasRef.current.height = webcamRef.current.video.videoHeight;
    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext("2d");
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    canvasCtx.drawImage(
      results.image,
      0,
      0,
      canvasElement.width,
      canvasElement.height
    );
    drawConnectors(
      canvasCtx,
      results.poseLandmarks,
      mediapipePose.POSE_CONNECTIONS,
      { color: "white", lineWidth: 1 }
    );
    // * The dots are the landmarks
    drawLandmarks(canvasCtx, results.poseLandmarks, {
      color: "red",
      lineWidth: 1,
      radius: 2,
    });
    canvasCtx.restore();
  }
  /*
({
      locateFile: (file) =>
        `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`,
    });
*/

  useEffect(() => {
    const userPose = new Pose({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`;
      },
    });

    userPose.setOptions({
      maxNumFaces: 1,
      minDetectionConfidence: 0.8,
      minTrackingConfidence: 0.8,
    });

    userPose.setOptions({
      selfieMode: false,
      modelComplexity: 0,
      smoothLandmarks: true,
      enableSegmentation: false,
      smoothSegmentation: false,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
      effect: "background",
    });

    userPose.onResults(onResults);
    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null
    ) {
      camera = new cam.Camera(webcamRef.current.video, {
        // no issues with the exaustive-deps. We do not need to store the camera object for current purposes
        onFrame: async () => {
          await userPose.send({ image: webcamRef.current.video });
        },
        width: 1280,
        height: 720,
      });
      camera.start();
    }
  }, []);

  return (
    <div className="camera-app position-relative">
      {isPoseReady ? (
        <div className="input-overlay left">
          <Form.Control
            type="text"
            readOnly
            placeholder={subjectSitting.toString()}
          />
          <Form.Control
            type="text"
            readOnly
            placeholder={stopwatch.toString()}
          />
        </div>
      ) : (
        ""
      )}

      <Webcam
        ref={webcamRef}
        style={{
          zindex: 9,
          width: 400,
          height: 225,
          display: "none",
          position: "absolute",
        }}
      />
      <canvas ref={canvasRef} className="camera"></canvas>
      <div className="landmark-grid-container"></div>
    </div>
  );
};

export default UserPose;
