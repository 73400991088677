import React from "react";
import Layout from '../../Core/Layout';
import { useParams } from "react-router-dom";

import VideoLoader from "../Common/VideoLoader";
//import send from '../Common/img/icon-save-fill.svg'


function App() {
const { patientId} =  useParams();

const sidebarContent = {
  'title':"Initial Assessment", 
  'parent':"All Patients",
  'parent_url':`/physiotherapy/${patientId}`,
  'menu' : 'assessment',
  'button' : 'next',
	'button_url': `/physiotherapy/${patientId}/sensorimotor`
}	  

  return (
  <>
    <Layout sidebarContent={sidebarContent} defaultkey='1'>
      <h2>Physical Examination</h2>
        <p className="lead">
          Range of Motion
        </p>
        <VideoLoader test="tug"></VideoLoader>
    </Layout>
  </>)
}

export default App;
