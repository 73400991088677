import { useState, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';

const TestButtons = ({ options, setTestData, setIsUserReady, setResults, isUserReady, isResultsReady, isPoseReady }) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const [selectedAssessment, setSelectedAssessment] = useState([]);


  const handleButtonClick = (option, index) => {
    setTestData(option);
    setIsUserReady(false);
    setResults(false);
    setActiveIndex(index);
    setSelectedAssessment(option.assessment || []);
  };

  useEffect(() => {
    // Set the initial test data and state on load
    if (options.tests) {
      const initialOption = options.tests[0];
      handleButtonClick(initialOption, 0);
    }
  }, [options.tests]);


  return (
    <>
       {isPoseReady && isUserReady && !isResultsReady?
            (
              <div className="input-overlay left ">
              {selectedAssessment && selectedAssessment.length >  0 && 
              selectedAssessment.map((elem) => (
            <>
                <Form.Control type="text" readOnly  placeholder={0}
              />
              <label className="mb-3 small">
                {elem}
              </label>
            </>
              ))}
              </div>
            
            ):''
            }
         
           <Row className="exercise-list position-absolute bottom-0"> 
            {Array.isArray(options.tests) &&
            options.tests.map((option, index) => (
              <>
              
                <Col key={index}>
                <Button variant="outline-light" size="sm"
                active={activeIndex === index}
                onClick={()=>{
                  handleButtonClick(option, index)}  
                  }>
                  {
                    option.icon?
                    (
                      <img 
                      src={option.icon} 
                      alt={option.name} 
                      className="img-fluid" />
                      ) : ''
                    }  
                    <p>
                     {option.name}
                    </p>
                    </Button>

                </Col>
                
   						</>	
            ))}
            
            </Row>

    </>
  );
};

export default TestButtons;
