import {
  angleBetweenVectors,
  computeDistance,
  computeSumOfDiffs,
} from "./utils";

import { mean } from "mathjs";

const Mode = Object.freeze({
  TWO_DIM: "2d angles",
  THREE_DIM: "3d angles",
});

export class ComputeParam {
  // Number of samples used for averaging
  N = 5;
  SIGNED_ANGLES = true;

  constructor(medCls) {
    this._med = medCls;
    this.mode = Mode.THREE_DIM;
    this.debugging = false;
    this._constructor();
  }

  _constructor() {
    this._initSagittalAngles();
    this._initHipAngles();
    this._initKneeAngles();
    this._initAnkleAngles();
    this._initStepLength();
    this._initNoseShoulderScreenLength();
  }

  _initSagittalAngles() {
    // Right sagital - angles, diff, mean diff
    this.anglesSagiR = [];
    this.anglesDiffSagiR = Array(this.N - 1).fill(0.0);
    this.anglesMeanDiffSagiR = Array(2 * this.N - 1).fill(0.0);
    // Left sagital - angles, diff, mean diff
    this.anglesSagiL = [];
    this.anglesDiffSagiL = Array(this.N - 1).fill(0.0);
    this.anglesMeanDiffSagiL = Array(2 * this.N - 1).fill(0.0);
  }

  _initHipAngles() {
    // Right hips - angles, diff, mean diff
    this.anglesHipR = [];
    this.anglesDiffHipR = Array(this.N - 1).fill(0.0);
    this.anglesMeanDiffHipR = Array(2 * this.N - 1).fill(0.0);
    // Left hips - angles, diff, mean diff
    this.anglesHipL = [];
    this.anglesDiffHipL = Array(this.N - 1).fill(0.0);
    this.anglesMeanDiffHipL = Array(2 * this.N - 1).fill(0.0);
  }

  _initKneeAngles() {
    // Right knee - angles, diff, mean diff
    this.anglesKneeR = [];
    this.anglesDiffKneeR = Array(this.N - 1).fill(0.0);
    this.anglesMeanDiffKneeR = Array(2 * this.N - 1).fill(0.0);
    // Left knee - angles, diff, mean diff
    this.anglesKneeL = [];
    this.anglesDiffKneeL = Array(this.N - 1).fill(0.0);
    this.anglesMeanDiffKneeL = Array(2 * this.N - 1).fill(0.0);
  }

  _initAnkleAngles() {
    // Right ankle - angles, diff, mean diff
    this.anglesAnkleR = [];
    this.anglesDiffAnkleR = Array(this.N - 1).fill(0.0);
    this.anglesMeanDiffAnkleR = Array(2 * this.N - 1).fill(0.0);
    // Left ankle - angles, diff, mean diff
    this.anglesAnkleL = [];
    this.anglesDiffAnkleL = Array(this.N - 1).fill(0.0);
    this.anglesMeanDiffAnkleL = Array(2 * this.N - 1).fill(0.0);
  }

  _initStepLength() {
    // Step length (2d and 3d)
    this.screenStepLength = [];
    this.realStepLength = [];
    // Step length diff, mean diff
    this.stepLengthDiff = Array(this.N - 1).fill(0.0);
    this.stepLengthMeanDiff = Array(2 * this.N - 1).fill(0.0);
  }

  _initNoseShoulderScreenLength() {
    this.screenNoseShoulderLengthPx = [];
  }

  main() {
    this.computeAngles();
    this.computeStepLength();
    this.computeSumAbsDiff();
    this.computeMeanSumAbsDiff();
    this.computeNoseShoulderLength();

    if (this._med.n === this._med.MAX_SAMPLE_LENGTH) {
      if (this.debugging) {
        this._debuggingComputeAngles();
        this._debuggingComputeStepLength();
        this._debuggingComputeSumAbsDiff();
        this._debuggingComputeMeanSumAbsDiff();
        this._debuggingComputeNoseShoulderLength();
      }
      this._reset();
    }
  }

  _debuggingComputeAngles() {
    console.log("----------------------------");
    console.log("Debugging console ComputeAngles");
    console.log("----------------------------");
    console.log(`Mode: ${this.mode}`);
    console.log(
      `anglesSagiL (length, first el): (${this.anglesSagiL.length}, ${this.anglesSagiL[0]})`
    );
    console.log(
      `anglesSagiR (length, first el): (${this.anglesSagiR.length}, ${this.anglesSagiR[0]})`
    );
    console.log(
      `anglesKneeL (length, first el): (${this.anglesKneeL.length}, ${this.anglesKneeL[0]})`
    );
    console.log(
      `anglesKneeR (length, first el): (${this.anglesKneeR.length}, ${this.anglesKneeR[0]})`
    );
    console.log(
      `anglesHipL (length, first el): (${this.anglesHipL.length}, ${this.anglesHipL[0]})`
    );
    console.log(
      `anglesHipR (length, first el): (${this.anglesHipR.length}, ${this.anglesHipR[0]})`
    );
    console.log(
      `anglesAnkleL (length, first el): (${this.anglesAnkleL.length}, ${this.anglesAnkleL[0]})`
    );
    console.log(
      `anglesAnkleR (length, first el): (${this.anglesAnkleR.length}, ${this.anglesAnkleR[0]})`
    );
  }

  computeAngles() {
    this._push2dSagittalAngle();
    if (this.mode === Mode.TWO_DIM) {
      this._push2dKneeAngle();
      this._push2dHipAngle();
      this._push2dAnkleAngle();
    } else if (this.mode === Mode.THREE_DIM) {
      this._push3dKneeAngle();
      this._push3dHipAngle();
      this._push3dAnkleAngle();
    }
    // console.log("computeAngles completed.");
  }

  _push2dSagittalAngle() {
    // Sagittal angles left
    this.anglesSagiL.push(
      angleBetweenVectors(
        [0, 0],
        [0, 1],
        this._med.joints.getLastScreenPosition("shoulder", "left"),
        this._med.joints.getLastScreenPosition("hip", "left")
      )
    );
    // Sagittal angles right
    this.anglesSagiR.push(
      angleBetweenVectors(
        [0, 0],
        [0, 1],
        this._med.joints.getLastScreenPosition("shoulder", "right"),
        this._med.joints.getLastScreenPosition("hip", "right")
      )
    );
  }

  _push2dKneeAngle() {
    // Knee angles left
    this.anglesKneeL.push(
      angleBetweenVectors(
        this._med.joints.getLastScreenPosition("hip", "left"),
        this._med.joints.getLastScreenPosition("knee", "left"),
        this._med.joints.getLastScreenPosition("knee", "left"),
        this._med.joints.getLastScreenPosition("heel", "left"),
        this.SIGNED_ANGLES
      )
    );
    // Knee angles right
    this.anglesKneeR.push(
      angleBetweenVectors(
        this._med.joints.getLastScreenPosition("hip", "right"),
        this._med.joints.getLastScreenPosition("knee", "right"),
        this._med.joints.getLastScreenPosition("knee", "right"),
        this._med.joints.getLastScreenPosition("heel", "right"),
        this.SIGNED_ANGLES
      )
    );
  }

  _push2dHipAngle() {
    // Hip angles left
    this.anglesHipL.push(
      angleBetweenVectors(
        [0, 0],
        [0, 1],
        this._med.joints.getLastScreenPosition("hip", "left"),
        this._med.joints.getLastScreenPosition("knee", "left"),
        this.SIGNED_ANGLES
      )
    );
    // Hip angles right
    this.anglesHipR.push(
      angleBetweenVectors(
        [0, 0],
        [0, 1],
        this._med.joints.getLastScreenPosition("hip", "right"),
        this._med.joints.getLastScreenPosition("knee", "right"),
        this.SIGNED_ANGLES
      )
    );
  }

  _push2dAnkleAngle() {
    // Ankle angle (-90 degree = when the foot is flat on the floor the ankle angle equals 0)
    // Ankle angles left
    let angle_l = angleBetweenVectors(
      this._med.joints.getLastScreenPosition("ankle", "left"),
      this._med.joints.getLastScreenPosition("knee", "left"),
      this._med.joints.getLastScreenPosition("ankle", "left"),
      this._med.joints.getLastScreenPosition("footIndex", "left"),
      this.SIGNED_ANGLES
    );

    // Ankle angles right
    let angle_r = angleBetweenVectors(
      this._med.joints.getLastScreenPosition("ankle", "right"),
      this._med.joints.getLastScreenPosition("knee", "right"),
      this._med.joints.getLastScreenPosition("ankle", "right"),
      this._med.joints.getLastScreenPosition("footIndex", "right"),
      this.SIGNED_ANGLES
    );

    this.anglesAnkleL.push(angle_l - Math.sign(angle_l) * 90);
    this.anglesAnkleR.push(angle_r - Math.sign(angle_r) * 90);
  }

  _push3dKneeAngle() {
    // Knee angles left
    this.anglesKneeL.push(
      angleBetweenVectors(
        this._med.joints.getLastRealPosition("hip", "left"),
        this._med.joints.getLastRealPosition("knee", "left"),
        this._med.joints.getLastRealPosition("knee", "left"),
        this._med.joints.getLastRealPosition("heel", "left")
      )
    );
    // Knee angles right
    this.anglesKneeR.push(
      angleBetweenVectors(
        this._med.joints.getLastRealPosition("hip", "right"),
        this._med.joints.getLastRealPosition("knee", "right"),
        this._med.joints.getLastRealPosition("knee", "right"),
        this._med.joints.getLastRealPosition("heel", "right")
      )
    );
  }

  _push3dHipAngle() {
    // Hip angles left
    this.anglesHipL.push(
      angleBetweenVectors(
        [0, 0, 0],
        [0, 1, 0],
        this._med.joints.getLastRealPosition("hip", "left"),
        this._med.joints.getLastRealPosition("knee", "left")
      )
    );
    // Hip angles right
    this.anglesHipR.push(
      angleBetweenVectors(
        [0, 0, 0],
        [0, 1, 0],
        this._med.joints.getLastRealPosition("hip", "right"),
        this._med.joints.getLastRealPosition("knee", "right")
      )
    );
  }

  _push3dAnkleAngle() {
    // Ankle angle (-90 degree = when the foot is flat on the floor the ankle angle equals 0)
    // Ankle angles left
    this.anglesAnkleL.push(
      angleBetweenVectors(
        this._med.joints.getLastRealPosition("ankle", "left"),
        this._med.joints.getLastRealPosition("knee", "left"),
        this._med.joints.getLastRealPosition("ankle", "left"),
        this._med.joints.getLastRealPosition("footIndex", "left")
      ) - 90.0
    );
    // Ankle angles right
    this.anglesAnkleR.push(
      angleBetweenVectors(
        this._med.joints.getLastRealPosition("ankle", "right"),
        this._med.joints.getLastRealPosition("knee", "right"),
        this._med.joints.getLastRealPosition("ankle", "right"),
        this._med.joints.getLastRealPosition("footIndex", "right")
      ) - 90.0
    );
  }

  _debuggingComputeStepLength() {
    console.log("----------------------------");
    console.log("Debugging console ComputeSteplLength");
    console.log("----------------------------");
    console.log(
      `screenStepLength (length, first el): (${this.screenStepLength.length}, ${this.screenStepLength[0]})`
    );
    console.log(
      `realStepLength (length, first el): (${this.realStepLength.length}, ${this.realStepLength[0]})`
    );
  }

  computeStepLength() {
    this.screenStepLength.push(
      computeDistance(
        this._med.joints.getLastScreenPosition("heel", "left"),
        this._med.joints.getLastScreenPosition("heel", "right")
      )
    );
    this.realStepLength.push(
      computeDistance(
        this._med.joints.getLastRealPosition("heel", "left"),
        this._med.joints.getLastRealPosition("heel", "right")
      )
    );
  }

  _debuggingComputeSumAbsDiff() {
    console.log("----------------------------");
    console.log("Debugging console ComputeSumAbsDiff");
    console.log("----------------------------");
    let start_idx = 0;
    let end_idx = 10;
    if (this._med.n > end_idx) {
      console.log(
        `anglesDiffSagiL (length, first 10 el): (${
          this.anglesDiffSagiL.length
        }, ${this.anglesDiffSagiL.slice(start_idx, end_idx)})`
      );
      console.log(
        `anglesDiffSagiR (length, el[${start_idx}, ${end_idx}]): (${
          this.anglesDiffSagiR.length
        }, ${this.anglesDiffSagiR.slice(start_idx, end_idx)})`
      );
      console.log(
        `anglesDiffKneeL (length, el[${start_idx}, ${end_idx}]): (${
          this.anglesDiffKneeL.length
        }, ${this.anglesDiffKneeL.slice(start_idx, end_idx)})`
      );
      console.log(
        `anglesDiffKneeR (length, el[${start_idx}, ${end_idx}]): (${
          this.anglesDiffKneeR.length
        }, ${this.anglesDiffKneeR.slice(start_idx, end_idx)})`
      );
      console.log(
        `anglesDiffHipL (length, el[${start_idx}, ${end_idx}]): (${
          this.anglesDiffHipL.length
        }, ${this.anglesDiffHipL.slice(start_idx, end_idx)})`
      );
      console.log(
        `anglesDiffHipR (length, el[${start_idx}, ${end_idx}]): (${
          this.anglesDiffHipR.length
        }, ${this.anglesDiffHipR.slice(start_idx, end_idx)})`
      );
      console.log(
        `anglesDiffAnkleL (length, el[${start_idx}, ${end_idx}]): (${
          this.anglesDiffAnkleL.length
        }, ${this.anglesDiffAnkleL.slice(start_idx, end_idx)})`
      );
      console.log(
        `anglesDiffAnkleR (length, el[${start_idx}, ${end_idx}]): (${
          this.anglesDiffAnkleR.length
        }, ${this.anglesDiffAnkleR.slice(start_idx, end_idx)})`
      );
      console.log(
        `stepLengthDiff (length, el[${start_idx}, ${end_idx}]): (${
          this.stepLengthDiff.length
        }, ${this.stepLengthDiff.slice(start_idx, end_idx)})`
      );
    }
  }
  computeSumAbsDiff() {
    if (this._med.n >= this.N) {
      this._pushSumAbsDiffSagittal();
      this._pushSumAbsDiffKnee();
      this._pushSumAbsDiffHip();
      this._pushSumAbsDiffAnkle();
      this._pushSumAbsDiffStepLength();
    }
  }

  _pushSumAbsDiffSagittal() {
    this.anglesDiffSagiL.push(computeSumOfDiffs(this.anglesSagiL, this.N));
    this.anglesDiffSagiR.push(computeSumOfDiffs(this.anglesSagiR, this.N));
  }

  _pushSumAbsDiffKnee() {
    this.anglesDiffKneeL.push(computeSumOfDiffs(this.anglesKneeL, this.N));
    this.anglesDiffKneeR.push(computeSumOfDiffs(this.anglesKneeR, this.N));
  }

  _pushSumAbsDiffHip() {
    this.anglesDiffHipL.push(computeSumOfDiffs(this.anglesHipL, this.N));
    this.anglesDiffHipR.push(computeSumOfDiffs(this.anglesHipR, this.N));
  }

  _pushSumAbsDiffAnkle() {
    this.anglesDiffAnkleL.push(computeSumOfDiffs(this.anglesAnkleL, this.N));
    this.anglesDiffAnkleR.push(computeSumOfDiffs(this.anglesAnkleR, this.N));
  }

  _pushSumAbsDiffStepLength() {
    this.stepLengthDiff.push(computeSumOfDiffs(this.realStepLength, this.N));
  }

  _debuggingComputeMeanSumAbsDiff() {
    console.log("----------------------------");
    console.log("Debugging console ComputeMeanSumAbsDiff");
    console.log("----------------------------");
    let start_idx = 0;
    let end_idx = 15;
    if (this._med.n > end_idx) {
      console.log(
        `anglesMeanDiffSagiL (length, first 10 el): (${
          this.anglesMeanDiffSagiL.length
        }, ${this.anglesMeanDiffSagiL.slice(start_idx, end_idx)})`
      );
      console.log(
        `anglesMeanDiffSagiR (length, el[${start_idx}, ${end_idx}]): (${
          this.anglesMeanDiffSagiR.length
        }, ${this.anglesMeanDiffSagiR.slice(start_idx, end_idx)})`
      );
      console.log(
        `anglesMeanDiffKneeL (length, el[${start_idx}, ${end_idx}]): (${
          this.anglesMeanDiffKneeL.length
        }, ${this.anglesMeanDiffKneeL.slice(start_idx, end_idx)})`
      );
      console.log(
        `anglesMeanDiffKneeR (length, el[${start_idx}, ${end_idx}]): (${
          this.anglesMeanDiffKneeR.length
        }, ${this.anglesMeanDiffKneeR.slice(start_idx, end_idx)})`
      );
      console.log(
        `anglesMeanDiffHipL (length, el[${start_idx}, ${end_idx}]): (${
          this.anglesMeanDiffHipL.length
        }, ${this.anglesMeanDiffHipL.slice(start_idx, end_idx)})`
      );
      console.log(
        `anglesMeanDiffHipR (length, el[${start_idx}, ${end_idx}]): (${
          this.anglesMeanDiffHipR.length
        }, ${this.anglesMeanDiffHipR.slice(start_idx, end_idx)})`
      );
      console.log(
        `anglesMeanDiffAnkleL (length, el[${start_idx}, ${end_idx}]): (${
          this.anglesMeanDiffAnkleL.length
        }, ${this.anglesMeanDiffAnkleL.slice(start_idx, end_idx)})`
      );
      console.log(
        `anglesMeanDiffAnkleR (length, el[${start_idx}, ${end_idx}]): (${
          this.anglesMeanDiffAnkleR.length
        }, ${this.anglesMeanDiffAnkleR.slice(start_idx, end_idx)})`
      );
      console.log(
        `stepLengthMeanDiff (length, el[${start_idx}, ${end_idx}]): (${
          this.stepLengthMeanDiff.length
        }, ${this.stepLengthMeanDiff.slice(start_idx, end_idx)})`
      );
    }
  }

  computeMeanSumAbsDiff() {
    if (this._med.n >= 2 * this.N) {
      this._pushMeanSumAbsDiffSagittal();
      this._pushMeanSumAbsDiffKnee();
      this._pushMeanSumAbsDiffHip();
      this._pushMeanSumAbsDiffAnkle();
      this._pushMeanSumAbsDiffStepLength();
    }
  }

  _pushMeanSumAbsDiffSagittal() {
    this.anglesMeanDiffSagiL.push(mean(this.anglesDiffSagiL.slice(-this.N)));
    this.anglesMeanDiffSagiR.push(mean(this.anglesDiffSagiR.slice(-this.N)));
  }

  _pushMeanSumAbsDiffKnee() {
    this.anglesMeanDiffKneeL.push(mean(this.anglesDiffKneeL.slice(-this.N)));
    this.anglesMeanDiffKneeR.push(mean(this.anglesDiffKneeR.slice(-this.N)));
  }

  _pushMeanSumAbsDiffHip() {
    this.anglesMeanDiffHipL.push(mean(this.anglesDiffHipL.slice(-this.N)));
    this.anglesMeanDiffHipR.push(mean(this.anglesDiffHipR.slice(-this.N)));
  }

  _pushMeanSumAbsDiffAnkle() {
    this.anglesMeanDiffAnkleL.push(mean(this.anglesDiffAnkleL.slice(-this.N)));
    this.anglesMeanDiffAnkleR.push(mean(this.anglesDiffAnkleR.slice(-this.N)));
  }

  _pushMeanSumAbsDiffStepLength() {
    this.stepLengthMeanDiff.push(mean(this.stepLengthDiff.slice(this.N)));
  }

  _debuggingComputeNoseShoulderLength() {
    console.log("----------------------------");
    console.log("Debugging console ComputeMeanSumAbsDiff");
    console.log("----------------------------");
    console.log(
      `screenNoseShoulderLengthPx (length, first el): 
      (${this.screenNoseShoulderLengthPx.length}, ${this.screenNoseShoulderLengthPx[0]})`
    );
  }

  computeNoseShoulderLength() {
    let left_dist = computeDistance(
      this._med.joints.getLastScreenPosition("shoulder", "left"),
      this._med.joints.getLastScreenNosePosition()
    );
    let right_dist = computeDistance(
      this._med.joints.getLastScreenPosition("shoulder", "right"),
      this._med.joints.getLastScreenNosePosition()
    );

    this.screenNoseShoulderLengthPx.push((left_dist + right_dist) / 2);
  }

  _reset() {
    this._constructor();
  }
}
