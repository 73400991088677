// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCNsfaA08RQ8xyt_fv-unFs9I7bENPMNs4",
  authDomain: "pendulum-8172e.firebaseapp.com",
  projectId: "pendulum-8172e",
  storageBucket: "pendulum-8172e.appspot.com",
  messagingSenderId: "1000892495288",
  appId: "1:1000892495288:web:148f3013f18f21b7c7cb4d",
  measurementId: "G-PNQPV2VHFC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

//TODO turn on analytics later
//const analytics = getAnalytics(app);

// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);