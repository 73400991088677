import React, { useCallback } from 'react'
import {Row,Col,Card} from 'react-bootstrap'
import Layout from '../Core/Layout'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';



function Home() {

	const dispatch = useDispatch();
	const userType = useSelector(state => state.userType);

	const handleClick = useCallback((value) => {
		dispatch({ type: 'SET_VARIABLE', payload: value });
	  }, [dispatch]);

	const sidebarContent = {
		'title':"Welcome",
		'message': "click on the left to start"
	  }	  

  return (
	<Layout sidebarContent={sidebarContent}>
	<Row className='h-100'>
		<Col>
			<h1>User type</h1>
			<Row>
				<Col className="mb-3" >
				<Card>
					<Card.Body>
							<h3>
								For Physiotherapists
							</h3>
							<p>
								A patient's current health state
							</p>


						</Card.Body>
						<Card.Footer className='text-end'>
							<Link onClick={()=>handleClick('therapist')}
							className="btn btn-primary"
							to="physiotherapy">Physiotherapy</Link>
						</Card.Footer>
					</Card>
					
				</Col>

				<Col className="mb-3" >
				<Card>
					<Card.Body>
							<h3>
								For Elder Care
							</h3>
							<p>
								A patient's current health state
							</p>


						</Card.Body>
						<Card.Footer className='text-end'>
							<Link onClick={()=>handleClick('elder')}
							className="btn btn-primary"
							to="physiotherapy">Elders</Link>
						</Card.Footer>
					</Card>
					
				</Col>
				
				
			</Row>
			<Row>
				<Col className="small text-muted">
					<hr />
					<h2>	
						Change list
					</h2>
					<ol>
						<li>Added persistant variable with user type using react redux</li>
						<li>updated side menu</li>

					</ol>
					<p>Last update <em>29 March 2023</em></p>
				</Col>
			</Row>
		</Col>
	</Row>
	</Layout>

  )
}

export default Home