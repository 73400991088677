// import { butterworthLowpassFilter } from "../utils";
// import { lowPassFilter } from "low-pass-filter";
// import { isThisMonth } from "date-fns";
import { detectLocalExtrema, mean } from "../utils";

export class ChairStandApp {
  // In seconds
  KNEE_ANGLE_TIME_WITHIN_INTERVAL_START = 1;
  KNEE_ANGLE_TIME_OUT_OF_INTERVAL = 0.1;
  // In degrees
  KNEE_ANGLE_SITTING_UPPER_TH = 120;
  KNEE_ANGLE_SITTING_LOWER_TH = 80;
  // In seconds
  TEST_DUR = 30.0;

  constructor(medCls, compCls) {
    this._med = medCls;
    this._comp = compCls;
    this._debugging = false;
    this.appStarted = false;
    this._constructor();
  }

  _constructor() {
    this._kneeAngleAvgR = [];
    this._kneeAngleAvgL = [];

    this._kneeAngleSamplesWithinInterval = 0;
    this._kneeAngleSamplesOutOfInterval = 0;

    this.sittingDetected = false;
    this._sittingIndex = null;

    this._startIndexDetected = false;
    this._startIndex = null;

    this._testEnded = false;
    this._endIndex = null;

    this.stopwatch = this.TEST_DUR;
    this.reps = 0;

    this._argMaxNose = [];
    this._argMinNose = [];
    this._noseShoulderDistCalculated = false;
  }

  main() {
    if (this._debugging && this._med.n % 100 === 0 && this.startApp) {
      this._debuggingReadData();
      this._debuggingGetNoseShoulderDist();
    }
    if (this._med.n === this._med.MAX_SAMPLE_LENGTH) {
      this._reset();
    }

    this.startApp();
    if (this.appStarted) {
      this.readData();
      this.getNoseShoulderDist();

      this.detectSitting();
      this.detectStartIndex();

      this.setNoseYPosLocalExtrema();
      this.discardNoseYLocalExtremaWithBentKnee();
      this.discardNoseYLocalExtremaSameRep();

      this.countdown();
      this.countReps();
    } else {
      console.log("App hasn't started running yet!");
    }
  }

  startApp() {
    if (this._med.fps !== null && this._med.n >= 2 * this._comp.N) {
      if (this._debugging && !this.appStarted) {
        console.log("The app has started!");
      }
      this.appStarted = true;
    }
  }

  _debuggingReadData() {
    console.log("----------------------------");
    console.log("Debugging console ReadData");
    console.log("----------------------------");
    console.log(
      `anglesKneeL (length, 10 last_el): (${
        this._anglesKneeL.length
      }, ${this._anglesKneeL.slice(-10)})`
    );
    console.log(
      `anglesKneeR (length, 10 last_el): (${
        this._anglesKneeR.length
      }, ${this._anglesKneeR.slice(-10)})`
    );
    console.log(
      `screenNose (length, last_el): (${
        this._screenNose.length
      }, ${this._screenNose.slice(-1)})`
    );
    console.log(
      `realNose (length, last_el): (${
        this._realNose.length
      }, ${this._realNose.slice(-1)})`
    );
    console.log(
      `noseShoulderDist (length, 10 last_el): (${
        this._arrNoseShoulderDist.length
      }, ${this._arrNoseShoulderDist.slice(-10)})`
    );
  }

  readData() {
    this._anglesKneeL = [...this._comp.anglesKneeL];
    this._anglesKneeR = [...this._comp.anglesKneeR];

    this._screenNose = this._med.joints.getAllScreenNosePositions();
    this._realNose = this._med.joints.getAllRealNosePositions();

    this._arrNoseShoulderDist = [...this._comp.screenNoseShoulderLengthPx];
  }

  _debuggingGetNoseShoulderDist() {
    console.log("----------------------------");
    console.log("Debugging console GetNoseShoulderDist");
    console.log("----------------------------");
    console.log(
      `noseShoulderDistCalculated: ${this._noseShoulderDistCalculated}`
    );
    console.log(`noseShoulderDist: ${this._noseShoulderDist}`);
  }

  getNoseShoulderDist() {
    if (!this._noseShoulderDistCalculated) {
      this._noseShoulderDist = this._arrNoseShoulderDist.slice(-1)[0];
      if (this._arrNoseShoulderDist.length > 20) {
        this._noseShoulderDist = mean(this._arrNoseShoulderDist);
        this._noseShoulderDistCalculated = true;
      }
    }
  }

  detectSitting() {
    const minSittingDuration =
      this.KNEE_ANGLE_TIME_WITHIN_INTERVAL_START * this._med.joints.fps;

    if (!this.sittingDetected) {
      let lkneeWithinInterval =
        this.KNEE_ANGLE_SITTING_LOWER_TH < this._anglesKneeL.slice(-1)[0] &&
        this.KNEE_ANGLE_SITTING_UPPER_TH > this._anglesKneeL.slice(-1)[0];
      let rkneeWithinInterval =
        this.KNEE_ANGLE_SITTING_LOWER_TH < this._anglesKneeR.slice(-1)[0] &&
        this.KNEE_ANGLE_SITTING_UPPER_TH > this._anglesKneeR.slice(-1)[0];

      if (lkneeWithinInterval || rkneeWithinInterval)
        this._kneeAngleSamplesWithinInterval++;
      else this._kneeAngleSamplesWithinInterval = 0;

      if (
        this._kneeAngleSamplesWithinInterval > minSittingDuration &&
        this._med.joints.bodyInFrame()
      ) {
        this._sittingIndex = this._med.n - 1;
        this.sittingDetected = true;
        this._kneeAngleSamplesWithinInterval = 0;
        console.log("Sitting detected!");
      }
    }
  }

  detectStartIndex() {
    const minStandingDuration =
      this.KNEE_ANGLE_TIME_OUT_OF_INTERVAL * this._med.joints.fps;

    if (this.sittingDetected && !this._startIndexDetected) {
      let lkneeBelowTh =
        this.KNEE_ANGLE_SITTING_LOWER_TH > this._anglesKneeL.slice(-1)[0];
      let rkneeBelowTh =
        this.KNEE_ANGLE_SITTING_LOWER_TH > this._anglesKneeR.slice(-1)[0];

      if (lkneeBelowTh && rkneeBelowTh) {
        this._kneeAngleSamplesOutOfInterval++;
        console.log(this._kneeAngleSamplesOutOfInterval);
      } else {
        this._kneeAngleSamplesOutOfInterval = 0;
      }

      if (this._kneeAngleSamplesOutOfInterval > minStandingDuration) {
        this._startIndex = this._med.n - 1;
        this._startIndexDetected = true;
        this._kneeAngleSamplesOutOfInterval = 0;
        this._startTime = Date.now();

        console.log("Starting Countdown!");
        console.log(`Start index: ${this._startIndex}`);
      }
    }
  }

  setNoseYPosLocalExtrema() {
    //
  }

  discardNoseYLocalExtremaWithBentKnee() {
    //
  }

  discardNoseYLocalExtremaSameRep() {
    //
  }

  countdown() {
    if (this._startIndexDetected && !this._testEnded) {
      this.stopwatch = this.TEST_DUR - (Date.now() - this._startTime) / 1000;
      console.log(this.stopwatch);
    }
    if (this.stopwatch < 0.0 && !this._testEnded) {
      this._testEnded = true;
      this._endIndex = this._med.n - 1;
      console.log(`End index: ${this._endIndex}`);
    }
  }

  countReps() {}

  _finalReport() {
    // console.log("##################################################");
    // console.log("################### TUG REPORT ###################");
    // console.log("##################################################");
    // console.log(`TUG was successfully completed in ${this.stopwatch} seconds`);
    // console.log(
    //   "An older adult who takes more than 12 seconds to complete the TUG is at risk for falling!"
    // );
  }

  _reset() {
    this._constructor();
  }
}
