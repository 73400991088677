import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import mic from "../Common/img/icon-mic-fill.svg";
import rec from "../Common/img/icon-record-fill.svg";
import stop from "../Common/img/icon-stop-fill.svg";

function CommentBox(props) {
  const [recStatus, setRecStatus] = useState(false);
  const [commentData, setCommentData] = useState("");

  function handleCommentChange(event) {
    setCommentData(event.target.value);
    props.onCommentChange(event.target.value);
  }

  
  function recVoice() {
    setRecStatus(true);
  }

  function stopVoice() {
    setRecStatus(false);
  }

  return (
    <Form.Group className="mb-3 form-big" controlId="voice-text">
      <Form.Label>Comments </Form.Label>
      <Form.Control
        as="textarea"
        rows={8}
        value={commentData}
        onChange={handleCommentChange}
      />
      {recStatus ? (
        <>
          <Button className="m-2" onClick={stopVoice}>
            <img src={stop} height="24" alt="Stop" />{" "}
          </Button>
          <img src={rec} height="24" alt="Record" /> Recording...
        </>
      ) : (
        <>
          <Button className="m-2" onClick={recVoice}>
            <img src={mic} height="24" alt="Record" />{" "}
          </Button>
        </>
      )}
    </Form.Group>
  );
}

export default CommentBox;