import React, { useState, useEffect } from 'react'
import {Row,Col, Navbar} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Header from './Header';

import Navigation from './Navigation';

function Sidebar({sidebarContent,defaultkey}) {

	const userType = useSelector(state => state.userType);

	let [data, setData] = useState('')

	useEffect(() => {
		if (sidebarContent) {
			setData(sidebarContent)
		   }
	   
	  return () => {
		//second
	  }
	}, [sidebarContent])
	

  return (
	<Col sm="12" lg={{span:3,order:2}} className='bg-dark overflow-hidden position-relative'>
		<Navbar collapseOnSelect  expand="lg" bg="dark" variant="dark" className="h-100 flex-column">
		
		
		<Header/>	
		
		{userType}

		<Navbar.Toggle aria-controls="basic-navbar-nav" />
		
		<Navbar.Collapse id="basic-navbar-nav"  className='flex-column w-100'>
			<div className="w-100 top mt-3">
				<Row>
					<Col>
						{
						
							data.parent ? (

							<p className='mb-0'>
								<Link to={data.parent_url} className='p-0 small'>
									Back to {data.parent}
								</Link>
							</p>
							) : ''
						
						}
						<h3>
							{
								data.title
							}
						</h3>
					</Col>
					
				</Row>
				
			</div>
			<p className="instruction">
				{
					data.message? data.message : '' 
				}
			</p>

			


			{
				data.menu ? (
					
					
					<Navigation menu={userType} defaultkey={defaultkey} data={data}/>
					
					
				):
				''
			}

			{
				data.button ?
				(
				<div className="text-center bottom-0 w-100 border-top pt-3 border-primary" id="secondary-button">
					<Link to={data.button_url} className={"btn btn-primary"} size="lg">
						{data.icon} {data.button}
					</Link>
				</div>
				)
				: ' '
				}
		
			  </Navbar.Collapse>
		
	</Navbar>

			
	</Col>
  )
}

export default Sidebar