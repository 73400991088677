// import { butterworthLowpassFilter } from "../utils";
// import { lowPassFilter } from "low-pass-filter";
// import { isThisMonth } from "date-fns";

export class TugApp {
  // In seconds
  KNEE_ANGLE_TIME_WITHIN_INTERVAL_START = 3;
  KNEE_ANGLE_TIME_WITHIN_INTERVAL_END = 0.5;
  KNEE_ANGLE_TIME_OUT_OF_INTERVAL = 0.2;
  MIN_TEST_DURATION = 3;

  KNEE_ANGLE_SITTING_UPPER_TH = 120;
  KNEE_ANGLE_SITTING_LOWER_TH = 80;

  constructor(medCls, compCls) {
    this._med = medCls;
    this._comp = compCls;
    this.debugging = false;
    this.appStarted = false;
    this._constructor();
  }

  _constructor() {
    this._kneeAngleSamplesWithinInterval = 0;
    this._kneeAngleSamplesOutOfInterval = 0;
    this.stopwatch = 0;

    this._startSittingIndex = null;
    this.startSittingDetected = false;

    this._startIndex = null;
    this._testStarted = false;

    this._endIndex = null;
    this._testEnded = false;

    this._turnIndex = null;
    this._turnDetected = false;
  }

  main() {
    if (this._med.n === this._med.MAX_SAMPLE_LENGTH) {
      if (this.debugging) {
        this._debuggingReadData();
        // this._debuggingButterworthFilterKneeAngle();
      }
      this._reset();
    }

    this.startApp();
    if (this.appStarted) {
      this.readData();
      //   this._debuggingReadData();
      //   this.butterworthFilterKneeAngle();
      //   this.butterworthFilterNosePos();
      this.detectSitting();
      this.startTest();
      this.measureTime();
      //   this.turnDetection();
      //   this.measurePathDistance();
      this.stopTest();
    } else {
      console.log("App hasn't started running yet!");
    }
  }

  startApp() {
    if (this._med.fps !== null && this._med.n >= 2 * this._comp.N) {
      if (this.debugging && !this.appStarted) {
        console.log("The app has started!");
      }
      this.appStarted = true;
    }
  }

  _debuggingReadData() {
    console.log("----------------------------");
    console.log("Debugging console ReadData");
    console.log("----------------------------");
    console.log(
      `anglesKneeL (length, 10 last_el): (${
        this.anglesKneeL.length
      }, ${this.anglesKneeL.slice(-10)})`
    );
    console.log(
      `anglesKneeR (length, 10 last_el): (${
        this.anglesKneeR.length
      }, ${this.anglesKneeR.slice(-10)})`
    );
    console.log(
      `screenNose (length, last_el): (${
        this.screenNose.length
      }, ${this.screenNose.slice(-1)})`
    );
    console.log(
      `realNose (length, last_el): (${
        this.realNose.length
      }, ${this.realNose.slice(-1)})`
    );
  }

  readData() {
    this.anglesKneeL = [...this._comp.anglesKneeL];
    this.anglesKneeR = [...this._comp.anglesKneeR];

    this.screenNose = this._med.joints.getAllScreenNosePositions();
    this.realNose = this._med.joints.getAllRealNosePositions();
  }

  _debuggingButterworthFilterKneeAngle() {
    console.log("----------------------------");
    console.log("Debugging consoleButterworthFilterKneeAngle");
    console.log("----------------------------");
    console.log(
      `anglesKneeLFiltered (length, 10 last_el): (${
        this.anglesKneeLFiltered.length
      }, ${this.anglesKneeLFiltered.slice(-10)})`
    );
    console.log(
      `anglesKneeRFiltered (length, 10 last_el): (${
        this.anglesKneeRFiltered.length
      }, ${this.anglesKneeRFiltered.slice(-10)})`
    );
  }

  butterworthFilterKneeAngle() {
    // TODO: later, not priority
    // this.anglesKneeLFiltered = [...this.anglesKneeL];
    // this.anglesKneeRFiltered = [...this.anglesKneeR];
    // let fc = 6;
    // if (this._med.joints.fps < 2 * fc) {
    //   fc = Math.floor(this._med.joints.fps / 2) - 1;
    // }
    // if (this._med.n > 18) {
    //   //
    // }
  }

  butterworthFilterNosePos() {
    // TODO: later, not priority
  }

  detectSitting() {
    const minSittingDuration =
      this.KNEE_ANGLE_TIME_WITHIN_INTERVAL_START * this._med.joints.fps;
    // console.log(this._kneeAngleSamplesWithinInterval);
    // console.log(this.anglesKneeL.slice(-1)[0]);
    // console.log(this.anglesKneeR.slice(-1)[0]);
    if (!this.startSittingDetected) {
      let lkneeWithinInterval =
        this.KNEE_ANGLE_SITTING_LOWER_TH < this.anglesKneeL.slice(-1)[0] &&
        this.KNEE_ANGLE_SITTING_UPPER_TH > this.anglesKneeL.slice(-1)[0];
      let rkneeWithinInterval =
        this.KNEE_ANGLE_SITTING_LOWER_TH < this.anglesKneeR.slice(-1)[0] &&
        this.KNEE_ANGLE_SITTING_UPPER_TH > this.anglesKneeR.slice(-1)[0];

      if (lkneeWithinInterval || rkneeWithinInterval)
        this._kneeAngleSamplesWithinInterval++;
      else this._kneeAngleSamplesWithinInterval = 0;

      if (
        this._kneeAngleSamplesWithinInterval > minSittingDuration &&
        this._med.joints.bodyInFrame()
      ) {
        this._startSittingIndex = this._med.n - 1;
        this.startSittingDetected = true;
        this._kneeAngleSamplesWithinInterval = 0;
        console.log("Sitting detected!");
      }
    }
  }

  startTest() {
    const minStandingDuration =
      this.KNEE_ANGLE_TIME_OUT_OF_INTERVAL * this._med.joints.fps;
    if (this.startSittingDetected && !this._testStarted) {
      let lkneeBelowTh =
        this.KNEE_ANGLE_SITTING_LOWER_TH > this.anglesKneeL.slice(-1)[0];
      let rkneeBelowTh =
        this.KNEE_ANGLE_SITTING_LOWER_TH > this.anglesKneeR.slice(-1)[0];

      if (lkneeBelowTh && rkneeBelowTh) this._kneeAngleSamplesOutOfInterval++;
      else this._kneeAngleSamplesOutOfInterval = 0;

      if (this._kneeAngleSamplesOutOfInterval > minStandingDuration) {
        this._startIndex = this._med.n - 1;
        this._testStarted = true;
        this._kneeAngleSamplesOutOfInterval = 0;
        this._startTime = Date.now();
        console.log("Starting Countdown!");
      }
    }
  }

  measureTime() {
    if (this._testStarted && !this._testEnded) {
      this.stopwatch = (Date.now() - this._startTime) / 1000;
      console.log(this.stopwatch);
    }
  }

  turnDetection() {
    // TODO: later
  }

  measurePathDistance() {
    // TODO later
  }

  stopTest() {
    const minSittingDuration =
      this.KNEE_ANGLE_TIME_WITHIN_INTERVAL_END * this._med.joints.fps;
    if (
      this._testStarted &&
      !this._testEnded &&
      this.stopwatch > this.MIN_TEST_DURATION
    ) {
      let lkneeWithinInterval =
        this.KNEE_ANGLE_SITTING_LOWER_TH < this.anglesKneeL.slice(-1)[0] &&
        this.KNEE_ANGLE_SITTING_UPPER_TH > this.anglesKneeL.slice(-1)[0];
      let rkneeWithinInterval =
        this.KNEE_ANGLE_SITTING_LOWER_TH < this.anglesKneeR.slice(-1)[0] &&
        this.KNEE_ANGLE_SITTING_UPPER_TH > this.anglesKneeR.slice(-1)[0];

      if (lkneeWithinInterval || rkneeWithinInterval)
        this._kneeAngleSamplesWithinInterval++;
      else this._kneeAngleSamplesWithinInterval = 0;

      if (
        this._kneeAngleSamplesWithinInterval > minSittingDuration &&
        this._med.joints.bodyInFrame()
      ) {
        this._endIndex = this._med.n - 1;
        this._testEnded = true;
        this._kneeAngleSamplesWithinInterval = 0;
        console.log("Sitting detected! Test is successfully completed!");
        this._finalReport();
      }
    }
  }

  _finalReport() {
    console.log("##################################################");
    console.log("################### TUG REPORT ###################");
    console.log("##################################################");
    console.log(`TUG was successfully completed in ${this.stopwatch} seconds`);
    console.log(
      "An older adult who takes more than 12 seconds to complete the TUG is at risk for falling!"
    );
  }

  _reset() {
    this._constructor();
  }
}
