import React from 'react'
import {Row,Col} from 'react-bootstrap'
import { format } from 'date-fns'


function PatientPainDisplay({item, pain_content}) {


  return (
	<>
		<Row>
			<Col className='text-light'>
				{format(item.date, 'dd MMM yyyy')} 
				| {JSON.stringify(pain_content)}
				{
				/*
				pain_content.map((item, index) => (
					<>
						{JSON.stringify(item)}
					</>
				))
				*/
				}
			</Col>
		</Row>

	</>
  )
}

export default PatientPainDisplay