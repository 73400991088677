import React, { useState, useEffect } from "react";
import {Row, Col} from 'react-bootstrap'
import PatientCard from '../Modules/Common/PatientCard'
import { format } from 'date-fns'
import { collection,getDocs } from "firebase/firestore";
import {db} from '../firebase';
//import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

// firebaseApps previously initialized using initializeApp()
//const db = getFirestore();
//connectFirestoreEmulator(db, 'localhost', 8080);
import Layout from '../Core/Layout';

function AllPatients() {
	
	
	const [patients, setPatients] = useState([]);

		useEffect(()=>{

			const fetchPost = async () => {
		
				await getDocs(collection(db, "patients"))
					.then((querySnapshot)=>{               
						const newData = querySnapshot.docs
							.map((doc) => ({...doc.data(), id:doc.id }));
						setPatients(newData);                
						//console.log(todos, newData);
					})
			
			}

			
			fetchPost();
		}, [])

	
		const sidebarContent = {
			'title':"All patients", 
			'parent':"Home",
			'parent_url':"/", 
			'button':"+ Add Patient",
			'button_url':"/new-patient", 
			'message':"Select patient to view profile"
			}
		

  return (
	<Layout sidebarContent={sidebarContent}>

			<div>
				<h1>
					
					All Patients
				</h1>
				<p className='lead'>{format(Date.now(), 'dd MMM yyyy')}</p>
				<Row className="animate">
					{patients?.map((item,i)=>(
						<Col key={i} xl="6" className='mb-3'><PatientCard patient={item} parent ></PatientCard></Col>
						))   
					}
				</Row>
			</div>		


	</Layout>
				
  )
}

export default AllPatients