import React from 'react'
import {Card,Row,Col,Button,OverlayTrigger,Popover} from 'react-bootstrap';
import { Link } from 'react-router-dom'
//import { format } from 'date-fns'

function PatientCard({patient,parent}) {


const popover = (
	<Popover id="popover-basic">
	  <Popover.Header as="h3">Insurance</Popover.Header>
	  <Popover.Body>
			<df>
				TK Krankenkasse
			</df>
			<dd>
				Health Insurance provider from Germany. Public health insurance 
			</dd>
	  </Popover.Body>
	</Popover>
  );

if (patient) {

	return (
	  <>
		<Card className="card profile">
		  <Card.Body>
				  <div className="card-content">
					  <Row>
						  <Col md="3">
							

							  {
								patient.img?
								(

									<img src={patient.img} 
										
									alt="Patient"
									  className="rounded-circle img-fluid profile border border-primary m-3" />
								
								) : ''
							  }
						  </Col>
						  <Col>
						  <div className="time h3">
							{
							/*
							parent? format((patient.body_treatment.treatment[0].appointment), 'hh:mm') : ''} {parent? ('- ' + format((patient.body_treatment.treatment[0].appointment + patient.body_treatment.treatment[0].length), 'hh:mm')) : ''}
							*/}
							</div>
						  <h3>
							  {patient.surname}, {patient.name}
						  </h3>
							<ul>
								<li>
									{patient.gender} ({patient.pronouns})	
								</li>
								<li>
									Age: {patient.age}
								</li>
								<li>
									ID: {patient.id}
								</li>

								{!parent?
								// conditional to parent element 
								(
								<>
									<li>
										Height: {patient.height}
									</li>
									<li>
										Weight: {patient.weight}
									</li>
									<li>
										Hand: {patient.handedness}
									</li>
								</>
								)
								:''
								}

								{patient.insurance?
								<li>
									Insurance: 
									<OverlayTrigger trigger="click" placement="right" overlay={popover}>										
										<Button  variant="link" className='d-inline m-0 py-0 px-1'>
										{patient.insurance} &nbsp;
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle-fill" viewBox="0 0 16 16">
										<path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
										</svg>
										</Button>
										</OverlayTrigger>
								</li>
								: ''
								}
							</ul>
						  </Col>
					  </Row>
				  </div>
		  </Card.Body>
		  {
		   parent ? (
		   	<Card.Footer className='text-end'>
		   		<Link to={patient.id} className='stretched-link btn btn-secondary mb-0'>See patient</Link>
	 		</Card.Footer>
			)
			: ''
		  }
		  
	  </Card>
	</>
	)

}

return (
	<>
	<Card className="card profile">
		  <Card.Body>
				  <div className="card-content">
					  <Row>
					  	<Col>
							No patient data
						</Col>
					  </Row>
					</div>
			</Card.Body>
	</Card>				  
	
	</>
)

	
}
  


export default PatientCard