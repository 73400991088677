import { useEffect, useRef, useState } from 'react';
//import { useEffect, useRef } from "react";
//import {Pose} from '@mediapipe/pose'
//import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";
//import * as mediapipePose from "@mediapipe/pose";
//const [repCounter, setRepCounter] = useState(null)
import { drawConnectors, drawLandmarks } from "@mediapipe/drawing_utils/drawing_utils";
import Webcam from 'react-webcam'
import * as cam from "@mediapipe/camera_utils";
import * as mediapipePose from "@mediapipe/pose";
import { Pose } from "@mediapipe/pose";
import { Form, Button } from 'react-bootstrap';
import TestButtons from '../Common/TestsButtons';
import TestResults from '../Assessment/TestResults';

//import { mediapipePose } from "@mediapipe/pose/pose";
/*
Source:
https://github.com/google/mediapipe/issues/3042

Live code:
https://codepen.io/mediapipe/pen/jOMbvxw

Contructor issue: 
https://github.com/google/mediapipe/issues/1719
https://github.com/google/mediapipe/issues/1976

Google reply: 
https://github.com/google/mediapipe/issues/1408

JS gpt reply: 
https://github.com/google/mediapipe/issues/1411

https://cdn.jsdelivr.net/npm/@mediapipe/camera_utils/index.d.ts
https://cdn.jsdelivr.net/npm/@mediapipe/control_utils/index.d.ts
https://cdn.jsdelivr.net/npm/@mediapipe/drawing_utils/index.d.ts
https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/index.d.ts
https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/index.d.ts
https://cdn.jsdelivr.net/npm/@mediapipe/pose/index.d.ts
https://cdn.jsdelivr.net/npm/@mediapipe/hands/index.d.ts
https://cdn.jsdelivr.net/npm/@mediapipe/holistic/index.d.ts

import {
  drawConnectors,
  drawLandmarks,
} from '@mediapipe/drawing_utils/drawing_utils';
import { Webcam } from '@mediapipe/camera_utils/camera_utils';
import { Pose } from '@mediapipe/pose/pose';
*/


const UserPose = ({onPoseReady, options}) => {
// * refs to the html elements
const webcamRef = useRef(null);
const canvasRef = useRef(null);
const cameraRef = useRef(null);   


// const landmarkRef = useRef(null);
let userPoseAngle = null;
//let camera = null;
let down;
let repsCounter = 0;


// sets Name of Test TODO use to also set which function should be executed in MediaPipe
const [testData, setTestData] = useState([]);

// checks if MediaPipe is gathering data
const [isPoseReady, setIsPoseReady] = useState(false);

// checks if MediaPipe is gathering data
const [isUserReady, setIsUserReady] = useState(false);

// checks if Results have been generated
const [isResultsReady, setResults] = useState(false);




// Use State saves the repsCounter variable and can be updated by calling the useState 
const [input_1, setInput_1] = useState(0)

// * function to draw the landmarks once the pose has been determined
function onResults(results,index) {
    let landmarks = results.poseLandmarks // * all the landmarks in the pose

    //  * getting the values for the three landmarks that we want to use
    try { // * we get errors every time the landmarks are not available
        // * will provide dynamic landmarks later "landmarks[mediapipePose.POSE_LANDMARKS.{landmark}]"
        let leftShoulder = landmarks[mediapipePose.POSE_LANDMARKS.LEFT_SHOULDER];
        let leftElbow = landmarks[mediapipePose.POSE_LANDMARKS.LEFT_ELBOW];
        let leftWrist = landmarks[mediapipePose.POSE_LANDMARKS.LEFT_WRIST];
        calculatePoseAngle(leftShoulder, leftElbow, leftWrist);
    } catch (error) {
        // console.error(error);
    }
    // Define the canvas element dimensions using the earlier created refs
    canvasRef.current.width = webcamRef.current.video.videoWidth
    canvasRef.current.height = webcamRef.current.video.videoHeight

    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext("2d")
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    canvasCtx.drawImage(results.image,
        0,
        0,
        canvasElement.width,
        canvasElement.height
    )
    
    drawConnectors(canvasCtx,
        results.poseLandmarks, mediapipePose.POSE_CONNECTIONS,
        { color: 'white', lineWidth: 1 });
    // * The dots are the landmarks 
    drawLandmarks(canvasCtx, results.poseLandmarks,
        { color: 'red', lineWidth: 1, radius: 2 });
    canvasCtx.restore();
    
      // checks if camera has been started to trigger
      if (!isPoseReady) {
        onPoseReady(true);
        setIsPoseReady(true);
      }
}

// * calculating the angles in the user pose
const calculatePoseAngle = (a, b, c) => {
    let radians = Math.atan2(c.y - b.y, c.x - b.x) - Math.atan2(a.y - b.y, a.x - b.x) // * fetching the radians using the atan2 function 
    let angle = radians * (180 / Math.PI) // * calculating the angle from the radian
    // need to provide dynamic values for angles as per requirement later along with the number of reps.
    if (angle > 180) { // * if the angle is greater than 180, then it is negative so changing it back to positive or an actual angle possible for a human being, lol..
        angle = 360 - angle
    }
    if (angle > 0 && angle < 180) { // * if the angle is positive, then it is a positive angle
        // console.log(angle.toFixed(2), "currentAngle");
    }
    userPoseAngle = angle.toFixed(2);
    calculateReps(userPoseAngle);
}
const calculateReps = (angle) => {
    // console.log(angle);
    if (angle >= 160) {
        down = true;
    }
    if (angle <= 40 && down) {
        down = false;
        // setRepCounter(repCounter + 1);
        repsCounter += 1;
        console.log(repsCounter, "repsCounter");

        // TODO changes the State of Input_1 variable 
        setInput_1(repsCounter)
    }

    
}

/*
({
      locateFile: (file) =>
        `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`,
    });
*/
    

// fixes the error variable inside a useEffect hook, which is not recommended because the value will be lost after each render




useEffect(() => {
         const userPose = new Pose({
        locateFile: (file) => {
            return `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`;
        }
    });
    userPose.setOptions({
        maxNumFaces: 1,
        minDetectionConfidence: 0.8,
        minTrackingConfidence: 0.8,
    });
    
    userPose.setOptions({
        selfieMode: false,
        modelComplexity: 2,
        smoothLandmarks: true,
        enableSegmentation: false,
        smoothSegmentation: false,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5,
        effect: 'background',
    });

    userPose.onResults(onResults);
    if (
        typeof webcamRef.current !== "undefined" &&
        webcamRef.current !== null
    ) {

    
        cameraRef.current = new cam.Camera(webcamRef.current.video, { // no issues with the exaustive-deps. We do not need to store the camera object for current purposes
            onFrame: async () => {
                await userPose.send({ image: webcamRef.current.video });
            },
            width: 1280,
            height: 720,
        });
        cameraRef.current.start();
        }
}, []);



// stops video and submits data

function setStopVideo() {
    if (cameraRef.current) {
        cameraRef.current.stop();
        const video = webcamRef.current.video;
        video.pause();
        video.srcObject = null;
        video.load();
        setResults(true);        
        setIsUserReady(true);        
    }
  }
  


return (
    <>
 
    
        <div className="camera-app position-relative">

        <TestButtons options={options} isResultsReady={isResultsReady} isPoseReady={isPoseReady} isUserReady={isUserReady} setTestData={setTestData} setIsUserReady={setIsUserReady} setResults={setResults}></TestButtons>

            <div className="user-ready text-center left w-75 p-5 " >
            {
                isPoseReady ? 
                isUserReady ? ''
                 : (
                <PreUser testData={testData} setIsUserReady={setIsUserReady}/>
                )
                : ''
            }
            </div>
            
         

            {isResultsReady  ?
                <div className="position-absolute left w-100">
                    <Results option={testData} data={input_1}></Results>
                </div>
            :''
            }


            <Webcam
                ref={webcamRef}
                style={{
                    zindex: 9,
                    width: 400,
                    height: 225,
                    display: "none",
                    position: "absolute",
                }}
            />
            
            <canvas
                ref={canvasRef}
                className={"camera " + (!isUserReady || isResultsReady ? "hide" : "true")}
            ></canvas>
            <div className="landmark-grid-container"></div>
            
                
              
                
            
        </div>

        

        <Button onClick={()=>setStopVideo()}>Stop Video</Button>
     
        
        </>

)
}


function PreUser({setIsUserReady, testData}) {
    return(
      <> 
        <h4>     
            {testData.name}
        </h4>
        
        <p>
            {testData.protocol}
        </p>

        <p>
            Start test: 
        </p>
        
        <p>
            <Button onClick={()=>setIsUserReady(true)}> ok </Button>
        </p>   
                

      </>
    )
  }

 function Results ({data,option}) {



    return(
        <>

            <TestResults data={data} option={option} chart />		

   
        </>
    )
 } 

export default UserPose;