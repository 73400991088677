import React, { useState } from 'react'
import PatientPainEditableSource from './PatientPainEditableSource'
import PatientPainDisplay from './PatientPainDisplay'
import {Row,Col,Button} from 'react-bootstrap'

import edit from '../Common/img/icon-edit.svg'
//import save from '../Common/img/icon-save-fill.svg'


function PatientPainEditable({item, editableItem}) {
  
  const [editable, setEditable] = useState(editableItem);
  const [pain_content, setPainContent] = useState([]);
  
 /*
  function saveItem() {
    setEditable(false)
  }
  */

  if (editable) {
    return (
          <>
          <PatientPainEditableSource item={item}  pain_content={pain_content} setPainContent={setPainContent} setEditable={setEditable}/>
          </>  
      )
  }

  return (
      <>
         <Row>	
          <Col className="text-end" xs="4" lg="3">
            <Button variant="outline-light" className="btn-sm" onClick={() => setEditable(true)}>
              <img src={edit}  height="24" alt="Edit icon" />
            </Button>
          </Col>
          <Col>
            <PatientPainDisplay item={item} pain_content={pain_content} setPainContent={setPainContent}/>
          </Col>
		    </Row>
         
    </>
    )
  
}

export default PatientPainEditable