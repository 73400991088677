// import { cross } from "mathjs";

const crossProduct2d = (a, b) => {
  let result = a[0] * b[1] - a[1] * b[0];
  return result;
};

export const angleBetweenVectors = (a0, a1, b0, b1, sign = false) => {
  // Getting position vector
  let v1 = [];
  for (let i = 0; i < a0.length; i++) {
    v1.push(a1[i] - a0[i]);
  }

  let v2 = [];
  for (let i = 0; i < b0.length; i++) {
    v2.push(b1[i] - b0[i]);
  }

  if (v1.length !== v2.length) {
    console.log("Vectors must be of the same dimension");
  }
  const dotProduct = v1.reduce((acc, val, i) => acc + val * v2[i], 0);
  const magnitudeProduct =
    Math.sqrt(v1.reduce((acc, val) => acc + val ** 2, 0)) *
    Math.sqrt(v2.reduce((acc, val) => acc + val ** 2, 0));
  const radians = Math.acos(dotProduct / magnitudeProduct);
  const degrees = (radians * 180) / Math.PI;

  if (sign === true) {
    let angle_sign = Math.sign(crossProduct2d(v1, v2));
    if (angle_sign === -1) return degrees * -1;
  }
  return degrees;
};

export const computeDistance = (a, b) => {
  let sum = 0;
  for (let i = 0; i < a.length; i++) {
    sum += Math.pow(a[i] - b[i], 2);
  }
  return Math.sqrt(sum);
};

export const computeSumOfDiffs = (list, n) => {
  let angles = list.slice(-n);
  let diffs = [];
  for (let i = 1; i < angles.length; i++) {
    diffs.push(Math.abs(angles[i] - angles[i - 1]));
  }
  let sum = diffs.reduce((a, b) => a + b, 0);
  return sum;
};

const addElements2list = (n, value) => {
  let result = [];
  for (let i = 0; i < n; i++) {
    result.push(value);
  }
  return result;
};

const iterateObjectProperties = (object_) => {
  Object.entries(object_).map((item) => {
    console.log(item);
  });
};

export const detectLocalExtrema = (arr, order = 1) => {
  let maxima = [];
  let minima = [];

  for (let i = order; i < arr.length - order; i++) {
    let isMax = true;
    let isMin = true;

    for (let j = i - order; j <= i + order; j++) {
      if (j !== i && arr[j] >= arr[i]) {
        isMax = false;
      }
      if (j !== i && arr[j] <= arr[i]) {
        isMin = false;
      }
    }

    if (isMax) {
      maxima.push(i);
    }
    if (isMin) {
      minima.push(i);
    }
  }

  return { maxima, minima };
};

export const mean = (arr) => {
  return arr.reduce((a, b) => a + b, 0) / arr.length;
};
