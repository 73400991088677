import React from 'react'
import TestLineChart from '../Common/TestLineChart'
import ChartData from './knee-angle.json'
import { format } from 'date-fns'
import {Row,Col,Table} from 'react-bootstrap'

function TestResults({title, chart, option, data}) {
  if (option) {
	return (
	<>
	<Row className=' py-5'>
		<Col className="text-center">
			<h3>
				{option.name} 
			</h3>
				{
                    option.icon?
                    (
                      <img 
                      src={option.icon} 
                      alt={option.name} 
                      className="img-fluid" />
                      ) : ''
                    }  
			<Table className='text-center text-light'>
				<thead>
					<tr>
						<th>
							Date
						</th>
						{option.assessment? option.assessment.map((elem)=>(
						<th>{elem}</th>
						)) : 'no data'}
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							{format(Date.now(), 'HH:MM - dd MMM yyyy')}
						</td>
						
						{option.assessment? option.assessment.map(()=>(
						<td>{data}</td>
						)) : 'no data'}
						
					</tr>
				</tbody>
			</Table>
		</Col>	
		<Col>
		{title}
			{
				chart ?
				<TestLineChart title={title} data={ChartData} x_axis="time" area="1" pointRadius="2" tension="0.4" limb="both"></TestLineChart>
				:
				''
			}
		</Col>
		
	</Row>
	</>
  )
}

return (
	<TestLineChart  title="title" data={ChartData} x_axis="time" area="1" pointRadius="20" tension="0.4" limb="both"></TestLineChart>
)
}

export default TestResults