import './css/bootstrap.min.css';
import './css/pendulum.css';
import { BrowserRouter as Router} from "react-router-dom";
import Whole from './Core/Whole';
import {Provider} from 'react-redux'
import store from './Core/GlobalVars'

function App() {

		return (
		<>
     <Provider store={store}>
      <div className="App">
        <Router>            
            <Whole/>            
        </Router>
      </div>
     </Provider>
		</>
		)
	
}

export default App;
